import React from "react";

export const childrenList = (items) => {
  return items.map((item) => {
    return item.active ? (
      <li className="active">
        <a href={item.itemLink}>
          <span className="icon">
            <i className="mdi mdi-table"></i>
          </span>
          <span className="menu-item-label uppercase">{item.name}</span>
        </a>
      </li>
    ) : (
      <li>
        <a href={item.itemLink}>
          <span className="icon">
            <i className="mdi mdi-square-edit-outline"></i>
          </span>
          <span className="menu-item-label uppercase">{item.name}</span>
        </a>
      </li>
    );
  });
};
export const SideBar = ({
  termActive,
  termName,
  termLink,
  items,
  orgClass,
}) => {
  return (
    <aside
      className={
        !orgClass ? "bg-slate-600 h-full" : "aside is-placed-left is-expanded"
      }
    >
      <div className="aside-tools">
        <a href="/">website</a>
        <span>/</span>
        <div>
          <b className="font-black uppercase">{termName}</b>
        </div>
      </div>
      <div className="menu is-menu-main">
        <p className="menu-label">General</p>
        <ul className="menu-list">
          <li className={termActive == true && "active"}>
            <a href={termLink}>
              <span className="icon">
                <i className="mdi mdi-desktop-mac"></i>
              </span>
              <span className="menu-item-label uppercase">dashboard</span>
            </a>
          </li>
        </ul>
        <p className="menu-label">Pages</p>
        {items && <ul className="menu-list">{childrenList(items)}</ul>}
        <p className="menu-label hidden">Actions</p>
        <ul className="menu-list hidden">
          <li>
            <a
              href="/dashboard/settings/header"
              onclick="alert('Coming soon'); return false"
              target="_blank"
              className="has-icon"
            >
              <span className="icon">
                <i className="mdi mdi-credit-card-outline"></i>
              </span>
              <span className="menu-item-label">Header section</span>
            </a>
          </li>
          <li>
            <a
              href="/dashboard/settings/slides"
              onclick="alert('Coming soon'); return false"
              target="_blank"
              className="has-icon"
            >
              <span className="icon">
                <i className="mdi mdi-credit-card-outline"></i>
              </span>
              <span className="menu-item-label">Slide section</span>
            </a>
          </li>
          <li>
            <a
              href="/dashboard/settings/footer"
              onclick="alert('Coming soon'); return false"
              target="_blank"
              className="has-icon"
            >
              <span className="icon">
                <i className="mdi mdi-credit-card-outline"></i>
              </span>
              <span className="menu-item-label">Footer section</span>
            </a>
          </li>
        </ul>
      </div>
    </aside>
  );
};
