import React, { Component } from "react";

export default class FeautureSection extends Component {
  render() {
    return (
      <div className="feature wow fadeInUp" data-wow-delay="0.1s">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-12 min-h-[300px]">
              <div className="feature-item">
                <div className="feature-icon">
                  <i className="flaticon-worker"></i>
                </div>
                <div className="feature-text">
                  <h3>Expert Worker</h3>
                  <p className="text-ellipsis overflow-hidden">
                    Experience the pinnacle of craftsmanship with our seasoned
                    construction and remodeling experts.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 min-h-[300px]">
              <div className="feature-item">
                <div className="feature-icon">
                  <i className="flaticon-building"></i>
                </div>
                <div className="feature-text">
                  <h3>Quality Work</h3>
                  <p className="text-ellipsis overflow-hidden">
                    Redefine excellence in construction and remodeling with our
                    unwavering commitment to quality work.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 min-h-[300px]">
              <div className="feature-item">
                <div className="feature-icon">
                  <i className="flaticon-call"></i>
                </div>
                <div className="feature-text">
                  <h3>24/7 Support</h3>
                  <p className="text-ellipsis overflow-hidden">
                    Your construction and remodeling needs don't adhere to a
                    strict schedule, and neither does our support. Our 24/7
                    support team is here to address your queries,
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
