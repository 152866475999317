import React, { Component } from "react";
import { Helmet } from "react-helmet";
import DashboardTemplate from "../../../templates/dashboard_template";

export default class UsersDashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Set your initial state here
    };
  }

  componentDidMount() {
    // Move code with side effects here
    // For example, API calls or subscriptions
  }

  exitFunction = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  showUsersTable(items) {
    return items.map((item, index) =>
      index % 2 == 0 ? (
        <tr>
          <td data-label="Name">{item.projectsUser}</td>
          <td data-label="Active/Inactive">
            {!item.isMember ? "inactive" : "active"}
          </td>
          <td data-label="Email">
            <small className="text-gray-500" title={item.email}>
              {item.email}
            </small>
          </td>
          <td className="actions-cell">
            <div className="buttons right nowrap">
              <button
                className={`button small ${
                  !item.isMember ? "red" : "green"
                } --jb-modal`}
                data-target="sample-modal-2"
                type="button"
              >
                <span className="icon">
                  <i className="mdi mdi-eye"></i>
                </span>
              </button>
              <button
                className="button small red --jb-modal"
                data-target="sample-modal"
                type="button"
              >
                <span className="icon">
                  <i className="mdi mdi-trash-can"></i>
                </span>
              </button>
            </div>
          </td>
        </tr>
      ) : (
        <tr>
          <td data-label="Name">{item.projectsUser}</td>
          <td data-label="Active/Inactive">
            {!item.isMember ? "inactive" : "active"}
          </td>
          <td data-label="Email">
            <small className="text-gray-500" title={item.email}>
              {item.email}
            </small>
          </td>
          <td className="actions-cell">
            <div className="buttons right nowrap">
              <button
                className={`button small ${
                  !item.isMember ? "red" : "green"
                } --jb-modal`}
                data-target="sample-modal-2"
                type="button"
              >
                <span className="icon"></span>
              </button>
              <button
                className="button small red --jb-modal"
                data-target="sample-modal"
                type="button"
              >
                <span className="icon">
                  <i className="mdi mdi-trash-can"></i>
                </span>
              </button>
            </div>
          </td>
        </tr>
      )
    );
  }

  render() {
    var { site } = this.props;
    return (
      <DashboardTemplate
        termName="users"
        termActive={false}
        termLink={`/dashboard`}
        items={[
          {
            name: "new project",
            itemLink: `/dashboard/settings/createproject`,
          },
          {
            active: true,
            name: "users",
            itemLink: `/dashboard/settings/users`,
          },
          {
            name: "projects",
            itemLink: `/dashboard/settings/projects`,
          },
          // {
          //   name: "themes settings",
          //   itemLink: `/dashboard/settings/themes`,
          // },
        ]}
        contentData={
          <div className="card has-table p-4">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-multiple"></i>
                </span>
                Users
              </p>

              <a href="#" className="card-header-icon">
                <span className="icon">
                  <i className="mdi mdi-reload"></i>
                </span>
              </a>
            </header>
            <div className="card-content">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>active/inactive</th>
                    <th>email</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {site &&
                    site.staffProjects &&
                    this.showUsersTable(site.staffProjects)}
                </tbody>
              </table>
              <div className="table-pagination hidden">
                <div className="flex items-center justify-between">
                  <div className="buttons">
                    <button type="button" className="button active">
                      1
                    </button>
                    <button type="button" className="button">
                      2
                    </button>
                    <button type="button" className="button">
                      3
                    </button>
                  </div>
                  <small>Page 1 of 3</small>
                </div>
              </div>
            </div>
          </div>
        }
        seo={
          <Helmet>
            <meta
              name="description"
              content="admin section of website |  users table section"
            />
            <title>Users page</title>

            {/* <link rel="canonical" href="http://example.com/example" /> */}
          </Helmet>
        }
      />
    );
  }

  renderold() {
    var { site } = this.props;
    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="admin section of website |  users table section"
          />
          <title>Users page</title>

          {/* <link rel="canonical" href="http://example.com/example" /> */}
        </Helmet>
        <div id="appadmin" className="flex justify-end">
          <aside className="aside is-placed-left is-expanded">
            <div className="aside-tools">
              <a href="/">website</a>
              <span>/</span>
              <div>
                <b className="font-black">Users</b>
              </div>
            </div>
            <div className="menu is-menu-main">
              <p className="menu-label">General</p>
              <ul className="menu-list">
                <li>
                  <a href="/dashboard">
                    <span className="icon">
                      <i className="mdi mdi-desktop-mac"></i>
                    </span>
                    <span className="menu-item-label">Dashboard</span>
                  </a>
                </li>
              </ul>
              <p className="menu-label">Pages</p>
              <ul className="menu-list">
                <li className="--set-active-tables-html">
                  <a href="/dashboard/settings/createproject">
                    <span className="icon">
                      <i className="mdi mdi-table"></i>
                    </span>
                    <span className="menu-item-label">Create a project</span>
                  </a>
                </li>
                <li className="active">
                  <a>
                    <span className="icon">
                      <i className="mdi mdi-square-edit-outline"></i>
                    </span>
                    <span className="menu-item-label">Users</span>
                  </a>
                </li>
                <li className="--set-active-profile-html">
                  <a href="/dashboard/settings/projects">
                    <span className="icon">
                      <i className="mdi mdi-account-circle"></i>
                    </span>
                    <span className="menu-item-label">Projects</span>
                  </a>
                </li>
                <li>
                  <a href="/dashboard/settings/themes">
                    <span className="icon">
                      <i className="mdi mdi-lock"></i>
                    </span>
                    <span className="menu-item-label">Themes settings</span>
                  </a>
                </li>
              </ul>
              <p className="menu-label">Actions</p>
              <ul className="menu-list">
                <li>
                  <a
                    href="/dashboard/settings/header"
                    onclick="alert('Coming soon'); return false"
                    target="_blank"
                    className="has-icon"
                  >
                    <span className="icon">
                      <i className="mdi mdi-credit-card-outline"></i>
                    </span>
                    <span className="menu-item-label">Header section</span>
                  </a>
                </li>
                <li>
                  <a
                    href="/dashboard/settings/slides"
                    onclick="alert('Coming soon'); return false"
                    target="_blank"
                    className="has-icon"
                  >
                    <span className="icon">
                      <i className="mdi mdi-credit-card-outline"></i>
                    </span>
                    <span className="menu-item-label">Slide section</span>
                  </a>
                </li>
                <li>
                  <a
                    href="/dashboard/settings/footer"
                    onclick="alert('Coming soon'); return false"
                    target="_blank"
                    className="has-icon"
                  >
                    <span className="icon">
                      <i className="mdi mdi-credit-card-outline"></i>
                    </span>
                    <span className="menu-item-label">Footer section</span>
                  </a>
                </li>
              </ul>
            </div>
          </aside>

          <section className="section main-section w-full lg:w-[calc(100vw-15rem)]">
            <div className="sticky top-0 z-50">
              <div className="w-full h-[3.5rem] mb-4 bgdashboard items-center flex justify-end">
                <div className="w-[60px] bg-yellow-400 h-full flex items-center justify-around">
                  <button onClick={this.exitFunction}>
                    <span>
                      <i className="fas fa-sign-out-alt"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div className="card has-table p-4">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-account-multiple"></i>
                  </span>
                  Users
                </p>

                <a href="#" className="card-header-icon">
                  <span className="icon">
                    <i className="mdi mdi-reload"></i>
                  </span>
                </a>
              </header>
              <div className="card-content">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>active/inactive</th>
                      <th>email</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {site &&
                      site.staffProjects &&
                      this.showUsersTable(site.staffProjects)}
                  </tbody>
                </table>
                <div className="table-pagination hidden">
                  <div className="flex items-center justify-between">
                    <div className="buttons">
                      <button type="button" className="button active">
                        1
                      </button>
                      <button type="button" className="button">
                        2
                      </button>
                      <button type="button" className="button">
                        3
                      </button>
                    </div>
                    <small>Page 1 of 3</small>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
