import React, { Component } from "react";
// services
import s1 from "../img/service-1.jpg";
import s2 from "../img/service-2.jpg";
import s3 from "../img/service-3.jpg";
import s4 from "../img/service-4.jpg";
import s5 from "../img/service-5.jpg";
import s6 from "../img/service-6.jpg";
import ImageWithSkeleton from "./image_skeleton";
export default class ServiceSection extends Component {
  render() {
    const { services } = this.props;
    return (
      <div className="service">
        <div className="container">
          <div className="section-header text-center">
            <p>Our Services</p>
            <h2>We Provide Services</h2>
          </div>
          <div className="row">
            {services &&
              services.map((service, index) => (
                <a
                  className="block col-lg-4 col-md-6 wow fadeInUp"
                  key={index}
                  href={"/services?service=" + service.serviceId}
                >
                  <div data-wow-delay={`${1 * (index + 1)}s`}>
                    <div className="service-item">
                      <div className="service-img">
                        <ImageWithSkeleton
                          index={index + 1}
                          src={
                            service.itemSrc.length > 0 ? service.itemSrc : s1
                          }
                          alt={service.name}
                          className="h-[20rem] w-full"
                        />
                        <div className="service-overlay">
                          <p>{service.description}</p>
                        </div>
                      </div>
                      <div className="service-text">
                        <h3>{service.name}</h3>
                        <a className="btn" href={s1} data-lightbox="service">
                          +
                        </a>
                      </div>
                    </div>
                  </div>
                </a>
              ))}
          </div>
        </div>
      </div>
    );
  }
}
