import "./App.css";
import Appforstore from "./Appforstore";
import { Provider } from "react-redux";
import store from "./store";
const App = () => {
  return (
    <Provider store={store}>
      <Appforstore />
    </Provider>
  );
};

export default App;
