import React, { Component } from "react";

export default class ScrollUp extends Component {
  render() {
    return (
      <a href="#" className="back-to-top">
        <i className="fa fa-chevron-up"></i>
      </a>
    );
  }
}
