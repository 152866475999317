"use client";

import React, { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { singInFunction } from "../../actions/auth_actions";
import { Button } from "../ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";

// Import statements remain the same

const FormBoxLoginWidget = ({}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const formSchema = z.object({
    email: z
      .string()
      .min(2, {
        message: "Email required for sign in",
      })
      .email({ message: "This is not a valid email." }),
    password: z.string().min(2, {
      message: "Password required",
    }),
  });
  // 1. Define your form.
  const form = useForm({
    mode: "onSubmit",
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  // 2. Define a submit handler.
  async function onSubmit(values) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.

    var dataforLogin = { ...values };

    setFormSubmitted(true);
    await singInFunction(dataforLogin.email, dataforLogin.password);

    
setFormSubmitted(false);
 
   
    form.reset({
      email: "",
      password: "",
    });
    
  }

  function onCancel() {
    // Reset the form to its default values

    console.log("cancel button");

    form.reset({
      email: "",
      password: "",
    });
  }

  return !localStorage.getItem("token") ? (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <Form {...form}>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <a href="/">
            <img
              className="mx-auto h-10 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              alt="Your Company"
            />
          </a>

          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            onReset={onCancel}
            className="space-y-4 flex-col justify-start w-full"
          >
            <div>
              <label
                for="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                {/* <input
          onChange={this.onChange}
          value={this.state.emailAddress}
          id="email"
          name="emailAddress"
          type="email"
          autocomplete="email"
          required
          className="block h-[60px] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        /> */}

                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      {/* <FormLabel>Your email</FormLabel> */}
                      <FormControl>
                        <Input
                          placeholder="email"
                          {...field}
                          className="block h-[60px] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </FormControl>
                      {/* <FormDescription>please type your email.</FormDescription> */}
                      <FormMessage className="flex justify-start py-4 login" />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  for="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="text-sm">
                  <a
                    href="#"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className="mt-2">
                {!formSubmitted && (
                  <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                      <FormItem>
                        {/* <FormLabel>Your email</FormLabel> */}
                        <FormControl>
                          <Input
                            placeholder="password"
                            {...field}
                            type="password"
                            className="block h-[60px] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </FormControl>
                        {/* <FormDescription>please type your email.</FormDescription> */}
                        <FormMessage className="flex justify-start py-4 login" />
                      </FormItem>
                    )}
                  />
                )}
                {formSubmitted && (
                  <input
                    disabled={true}
                    type="password"
                    security={true}
                    value={form.password}
                    className="block h-[60px] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                )}
                {/* <input
          onChange={this.onChange}
          value={this.state.password}
          id="password"
          name="password"
          type="password"
          autocomplete="current-password"
          required
          className="h-[60px] block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        /> */}
              </div>
            </div>

            <div>
              {!formSubmitted && (
                <button
                  // onClick={this.submitButton}
                  className="h-[60px] flex w-full justify-center items-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign in
                </button>
              )}

              {formSubmitted  && (
                <button
                  disabled={true}
                  // onClick={this.submitButton}
                  className="h-[60px] flex w-full justify-center items-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  waiting
                </button>
              )}
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?
            <a
              href="/register"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Register your account
            </a>
          </p>
        </div>
      </Form>
    </div>
  
  ) : localStorage.getItem("token") ? (
    (window.location.href = "/dashboard")
  ) : (
    <div className="w-full bg-red-500 h-[500px] content-center flex">loading </div>
  );
};

export default FormBoxLoginWidget;
