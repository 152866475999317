"use client";

import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "../ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import PhoneInput from "react-phone-input-2";
import { postData } from "../../actions/site_action";
// import "react-phone-input-2/lib/style.css";


// Import statements remain the same

const FormBoxContactWidget = ({ item }) => {
  const formSchema = z.object({
    fullName: z.string().min(2, {
      message: "name required for getting quote of " + item?.name,
    }),
    email: z.string().min(2, {
      message: "Email required for getting quote of " + item?.name,
    }).email({message:"This is not a valid email."}),

    phone: z.string().min(2, {
      message: "phone required for getting quote of " + item?.name,
    }),

    description: z.string().min(2, {
      message: "description required for getting quote of " + item?.name,
    }),
  });
  // 1. Define your form.
  const form = useForm({
    mode: "onSubmit",
    resolver: zodResolver(formSchema),
    defaultValues: {
      fullName: "",
      email: "",
      phone: "",
      description:""
    },
  });

  // 2. Define a submit handler.
  function onSubmit(values) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    


    var dataforSave = {...values, ...item};

    // console.log("thias is it",dataforSave);

    var dataObj= {

      emailCustomer : dataforSave.email,
      fullName : dataforSave.fullName,
      quoteTopic : "from contact page",
      phoneCustomer : dataforSave.phone,
      description : dataforSave.description,
    };
    
        postData(dataObj);
    
        form.reset({
          fullName: "",
          email: "",
          description:"",
          phone:"+1"
        });

    // form.reset({
    //     fullName: "",
    //     email: "",
    //     phone: "",
    //   });


  }


  function onCancel() {
       // Reset the form to its default values


       console.log("cancel button", );

       form.reset({
        fullName: "",
        description:"",
        email: "",
        phone: "+1",
      });
      
    
  }
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        onReset={onCancel}
        className="space-y-4 flex-col justify-start w-full"
      >

        <h2>Contact Us</h2>
        <FormField
          control={form.control}
          name="fullName"
          render={({ field }) => (
            <FormItem>
              {/* <FormLabel>Your email</FormLabel> */}
              <FormControl>
                <Input placeholder="full name" {...field} className="h-[3.5rem]" />
              </FormControl>
              {/* <FormDescription>please type your email.</FormDescription> */}
              <FormMessage className="flex justify-start py-4" />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              {/* <FormLabel>Your email</FormLabel> */}
              <FormControl>
                <Input placeholder="email" {...field} className="h-[3.5rem]" />
              </FormControl>
              {/* <FormDescription>please type your email.</FormDescription> */}
              <FormMessage className="flex justify-start py-4" />
            </FormItem>
          )}
        />

<FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              {/* <FormLabel>Your email</FormLabel> */}
              <FormControl>
                <textarea placeholder="your message" {...field} className="h-[3.5rem] p-3 border-grey-300 border-2 w-full" />
              </FormControl>
              {/* <FormDescription>please type your email.</FormDescription> */}
              <FormMessage className="flex justify-start py-4" />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          className="two"
          name="phone"
          render={({ field }) => (
            <FormItem className="flex-col justify-start w-full">
              {/* <FormLabel>Your email</FormLabel> */}
              <FormControl className="here">
                {/* <Input type="number" placeholder="phone" {...field} className="h-[3.5rem]"/> */}

                <PhoneInput
                  {...field}
                  specialLabel=""
                  country={"us"}

                  // onChange={(phone) =>

                  // }
                />
              </FormControl>
              {/* <FormDescription>please type your email.</FormDescription> */}
              <FormMessage className="flex justify-start py-4" />
            </FormItem>
          )}
        />

        <div className="flex justify-between">
          {/* <AlertDialogCancel>cancel quote</AlertDialogCancel> */}
          <Button type="submit">Submit</Button>
          <Button type="reset" variant="outline">reset</Button>

          
        </div>
      </form>
    </Form>
  );
};

export default FormBoxContactWidget;
