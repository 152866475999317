import React from "react";
import NormalTemplate from "../templates/normal_template";

const NotFound = () => {
  return (
    <NormalTemplate
      navs={[
        {
          name: "home",
          routeName: "/",
        },
        {
          name: "about us",
          routeName: "/about",
        },
        {
          name: "services",
          routeName: "/services",
        },
        {
          name: "contact",
          routeName: "/contact",
        },
      ]}
      authenticated={false}
    >
      <section class="h-[calc(90vh-90px)] relative isolate overflow-hidden px-6 py-24 sm:py-32 lg:px-8 bg-indigo-800 items-center flex">
        <div class="absolute inset-0 -z-10 bg-[radial-gradient(30rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20"></div>
        <div class="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-45deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center"></div>

        <div class="mx-auto max-w-2xl lg:max-w-4xl">
          <figure class="">
            <blockquote class="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
              <h2 className="font-extrabold px-4">404 Not Found</h2>

              <p>Sorry, the page you are looking for does not exist.</p>
            </blockquote>
          </figure>
        </div>
      </section>
    </NormalTemplate>
  );
};

export default NotFound;
