import axios from "axios";
import {
  FIND_ALL_ADDRESS,
  UPLOAD_PROCESS,
  FIND_ALL_PROJECTS_RELATED_USER,
  SINGLE_PROJECT,
} from "./types";

export const singleProject = (projectID) => async (dispatch) => {
  try {
    const response = await fetch(
      // "https://a5hjwpyn54.execute-api.us-east-2.amazonaws.com/demo/staffs/43c308c0-fb02-4266-8a27-3c78de311c7b/relatedprojects"
      `https://a5hjwpyn54.execute-api.us-east-2.amazonaws.com/demo/staffs/43c308c0-fb02-4266-8a27-3c78de311c7b/workingareas/${projectID}`
    );
    const itemResult = await response.json();
    console.log("single project: " + itemResult);

    dispatch({
      type: SINGLE_PROJECT,
      payload: itemResult,
    });
  } catch (e) {}
};

export const finAllProjectsRelatedUser = () => async (dispatch) => {
  try {
    const response = await fetch(
      "https://a5hjwpyn54.execute-api.us-east-2.amazonaws.com/demo/staffs/43c308c0-fb02-4266-8a27-3c78de311c7b/relatedprojects"
    );
    const itemResult = await response.json();
    console.log("all projects: " + itemResult);

    dispatch({
      type: FIND_ALL_PROJECTS_RELATED_USER,
      payload: itemResult,
    });
  } catch (e) {
    console.log("projects has error: " + JSON.stringify(e));
  }
};

export const findAllAddresses = (addressData) => (dispatch) => {
  axios
    .get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${addressData}.json?access_token=pk.eyJ1Ijoic29ocmFiNDAiLCJhIjoiY2toazNwNTFjMHJxeDJ6c2R4eXRrMHNpayJ9.FcGKt0dYJvZ1K0w9LlrPhg`
    )
    .then((res) =>
      dispatch({
        type: FIND_ALL_ADDRESS,
        payload: res.data.features,
      })
    )
    .catch((err) => console.error(err));
};

export const uploadImage2 = (imageFile) => (dispatch) => {
  var fileData = {
    file: imageFile.file,
    filename: imageFile.filename,
  };
  axios.put(
    "https://s3.us-west-1.amazonaws.com/projects-images-remodel/manzarkia-logo.png"
  );
};

export const finalResult = () => {
  return "final item uploaded";
};

export const uploadImage = (imageFile) => async (dispatch) => {
  console.log("we run this part");
  const onUploadProgress = (e) => {
    const percentage = Math.round((100 * e.loaded) / e.total);
    dispatch({
      type: UPLOAD_PROCESS,
      payload: percentage,
    });
  };

  try {
    var config = {
      method: "put",
      url: `https://s3.us-west-1.amazonaws.com/projects-images-remodel/${imageFile.name}`,
      headers: {
        "Content-Type": imageFile.type,
      },
      data: imageFile,
      onUploadProgress,
    };

    axios(config)
      .then(function (response) {
        // console.log("upload data", JSON.stringify(response));
        console.log("we run this part 2");

        console.log(
          "filePath.split('/').pop()",
          imageFile.name.replace(/ /g, "%20")
        );

        console.log(
          "what we have as response",
          response.status,
          "json item",
          `https://s3.us-west-1.amazonaws.com/projects-images-remodel/${imageFile.name.replace(
            / /g,
            "%20"
          )}`
        );
        if (response.status == 200) {
          var data = JSON.stringify(response, null, 2);
          console.log(
            "we load this for finalized",
            JSON.parse(data).config.url
          );

          return JSON.parse(data).config.url;
        } else {
          console.log("we load this for");
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    // console.log(response);
  } catch (error) {
    console.log(error);
  }
};

export const uploadFinished = (item) => {
  console.log("we reach end of the fucntion");
  return "item nice rebele";
};
