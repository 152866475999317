import React, { Component } from "react";

export default class NavSection extends Component {
  constructor() {
    super();
    this.state = {
      buttonClicked: false,
    };
  }

  handleClick = () => {
    this.setState({
      buttonClicked: !this.state.buttonClicked,
    });
    // this.setState({
    //   buttonClicked: !buttonClicked,
    // });
  };
  render() {
    var { buttonClicked } = this.state;
    const { navs } = this.props;
    return (
      <div className="nav-bar">
        <div className="container-fluid">
          <nav className="bg-gray-800">
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <button
                    type="button"
                    onClick={this.handleClick}
                    className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    aria-controls="mobile-menu"
                    aria-expanded="false"
                  >
                    <span className="absolute -inset-0.5"></span>
                    <span className="sr-only">Open main menu</span>

                    <svg
                      className="block h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                      />
                    </svg>

                    <svg
                      className="hidden h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      className="h-8 w-auto"
                      src="/logo512.png"
                      alt="Your Company"
                    />
                  </div>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {navs &&
                        navs.map((nav) =>
                          !nav.active ? (
                            nav.subItems ? (
                              <span key={nav.routeName}>
                                <a
                                  href={nav.routeName}
                                  className="dropdown-toggle text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium uppercase"
                                  aria-current="page"
                                  data-toggle="dropdown"
                                >
                                  {nav.name}
                                </a>
                                <div className="dropdown-menu">
                                  {nav.subItems.map((subNav) => (
                                    <a
                                      key={subNav.name}
                                      href={subNav.routeName}
                                      className="dropdown-item"
                                    >
                                      {subNav.name}
                                    </a>
                                  ))}
                                </div>
                              </span>
                            ) : (
                              <span key={nav.routeName}>
                                <a
                                  href={nav.routeName}
                                  className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium uppercase"
                                >
                                  {nav.name}
                                </a>
                              </span>
                            )
                          ) : nav.subItems ? (
                            <span key={nav.routeName}>
                              <a
                                href={nav.routeName}
                                className="dropdown-toggle bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium uppercase"
                                aria-current="page"
                                data-toggle="dropdown"
                              >
                                {nav.name}
                              </a>
                              <div className="dropdown-menu">
                                {nav.subItems.map((subNav) => (
                                  <a
                                    key={subNav.name}
                                    href={subNav.routeName}
                                    className="dropdown-item"
                                  >
                                    {subNav.name}
                                  </a>
                                ))}
                              </div>
                            </span>
                          ) : (
                            <span key={nav.routeName}>
                              <a
                                href={nav.routeName}
                                className="bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium uppercase"
                                aria-current="page"
                              >
                                {nav.name}
                              </a>
                            </span>
                          )
                        )}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <button
                    type="button"
                    className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="absolute -inset-1.5"></span>
                    <span className="sr-only">View notifications</span>
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                      />
                    </svg>
                  </button>

                  <div className="relative ml-3">
                    <div>
                      <a
                        href="/dashboard"
                        type="button"
                        className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        id="user-menu-button"
                        aria-expanded="false"
                        aria-haspopup="true"
                      >
                        <span className="absolute -inset-1.5"></span>
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src="https://projects-images-remodel.s3.us-west-1.amazonaws.com/sohrab.jpg"
                          // src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        />
                      </a>
                    </div>

                    <div
                      className="hidden absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-red-600 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu-button"
                      tabIndex="-1"
                    >
                      <a
                        href="/"
                        className="block px-4 py-2 text-sm text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="user-menu-item-0"
                      >
                        Your Profile
                      </a>
                      <a
                        href="/"
                        className="block px-4 py-2 text-sm text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="user-menu-item-1"
                      >
                        Settings
                      </a>
                      <a
                        href="/"
                        className="block px-4 py-2 text-sm text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="user-menu-item-2"
                      >
                        Sign out
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`sm:hidden ${buttonClicked ? "collapsed" : "collapse"
                }`}
              id="mobile-menu"
            >
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navs &&
                  navs.map((nav) =>
                    !nav.active ? (
                      !nav.subItems ? (
                        <a
                          key={nav.routeName}
                          href={nav.routeName}
                          className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium uppercase"
                        >
                          {nav.name}
                        </a>
                      ) : (
                        <div key={nav.routeName}>
                          <a
                            href={nav.routeName}
                            className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium uppercase"
                          >
                            {nav.name}
                          </a>
                          {nav.subItems && (
                            <div>
                              {nav.subItems.map((subNav) => (
                                <a
                                  key={subNav.routeName}
                                  href={subNav.routeName}
                                  className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium uppercase ml-6"
                                >
                                  {subNav.name}
                                </a>
                              ))}
                            </div>
                          )}
                        </div>
                      )
                    ) : (
                      <div key={nav.routeName}>
                        <a
                          href={nav.routeName}
                          className="bg-gray-900 text-white block rounded-md px-3 py-2 text-base font-medium uppercase"
                          aria-current="page"
                        >
                          {nav.name}
                        </a>
                        {nav.subItems && (
                          <div>
                            {nav.subItems.map((subNav) => (
                              <a
                                key={nav.routeName}
                                href={subNav.routeName}
                                className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium uppercase ml-6"
                              >
                                {subNav.name}
                              </a>
                            ))}
                          </div>
                        )}
                      </div>
                    )
                  )}
              </div>
            </div>
          </nav>

          <nav className="hidden navbar navbar-expand-lg bg-dark navbar-dark">
            <a href="/" className="navbar-brand">
              MENU
            </a>

            <div className="sm:hidden">
              <button
                type="button"
                className="navbar-toggler"
                data-toggle="collapse"
                data-target="#navbarCollapse"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>

            <div
              className="navbar-collapse justify-content-between injast"
              id="navbarCollapse"
            >
              <div className="navbar-nav mr-auto">
                <a href="index.html" className="nav-item nav-link active">
                  <span>
                    <i className="fa-icon">home</i>
                  </span>
                </a>
                <a href="about.html" className="nav-item nav-link">
                  About
                </a>
                <a href="service.html" className="nav-item nav-link">
                  Service
                </a>
                <a href="team.html" className="nav-item nav-link">
                  Team
                </a>
                <a href="portfolio.html" className="nav-item nav-link">
                  Project
                </a>
                <div className="nav-item dropdown">
                  <a
                    href="/"
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Pages
                  </a>
                  <div className="dropdown-menu">
                    <a href="blog.html" className="dropdown-item">
                      Blog Page
                    </a>
                    <a href="single.html" className="dropdown-item">
                      Single Page
                    </a>
                  </div>
                </div>
                <a href="contact.html" className="nav-item nav-link">
                  Contact
                </a>
              </div>
              <div className="ml-auto">
                <a className="btn" href="/">
                  Get a Free Quote
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}
