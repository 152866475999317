import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { GET_CURRENT_USER, SET_CURRENT_USER, LOAD_CURRENT_USER } from "./types";
import { AlertDialog } from "@radix-ui/react-alert-dialog";
import Swal from "sweetalert2";

function checkDateTimeAvailability(firstTime, secondTime) {
  var dateNow = new Date(Date.now());
  return (
    dateNow.getTime() / 1000 > firstTime ||
    dateNow.getTime() / 1000 < secondTime
  );
}
export const singInFunction = async (emailValue, passwordValue) => {
  // user details
  // var userData = {
  //   aud: "22ed5d1v29j9o2l2b87ja8g0kh",
  //   auth_time: 1701033418,
  //   cognitoUsername: "remodelchief",
  //   customZipcode: "94523",
  //   email: "remodelchief@gmail.com",
  //   email_verified: true,
  //   event_id: "593aa5b4-900e-4da1-afce-3fbce0613439",
  //   exp: 1701037018,
  //   iat: 1701033418,
  //   iss: "https://cognito-idp.us-east-2.amazonaws.com/us-east-2_Nk2R67WCb",
  //   name: "remodelchief",
  //   phone_numbe: "+15103861072",
  //   phone_number_verified: false,
  //   sub: "43c308c0-fb02-4266-8a27-3c78de311c7b",
  //   token_use: "id",
  // };

  var loginFormData = {
    email: emailValue.toString(),
    password: passwordValue.toString(),
  };
  var apidata = {
    LoginFormData: loginFormData,
  };

  var errors = [];
  // console.log("part 1", apidata);
  // // try {
  var resultLogin = await axios
    .post(
      "https://29mgbiw5ig.execute-api.us-east-2.amazonaws.com/testnew/loginform",
      apidata
    )
    .catch((err) => {
      console.log("login faced error: ", err);
      errors.unshift(err);
    });

  console.log("shw this code forever", resultLogin);
  if (errors.length === 0) {
    if (resultLogin.statusCode == 400) {
      // return jsonDecode(resultLogin.body);
      console.log("login error");
      return;
    } else {
      const token = resultLogin.data;
      const userData = jwtDecode(resultLogin.data);
      console.log("decoded user now for current", userData);

      if (checkDateTimeAvailability(userData.iat, userData.exp)) {
        localStorage.setItem("issuedTime", userData.iat);
        localStorage.setItem("expiredTime", userData.exp);
        localStorage.setItem("hasSession", true);
        localStorage.setItem("hastoken", true);
        localStorage.setItem("token", token);
        console.log("localStorage saved");
      } else {
        localStorage.clear();
      }
    }
  } else {
    console.log("fatallogin errr");

    Swal.fire({
      title: "Error!",
      text: errors[0]?.message,
      icon: "error",
      confirmButtonText: "please try again",
    });
  }
  // if (resultLogin.statusCode == 200) {
  // var attempTime = new Date(Date.now());
  // var tokenresult = await resultLogin.body.toJson();
  // print("tokenresult $tokenresult");
  // var getuserData = JwtDecoder.decode(tokenresult);
  // _tokenApp = tokenresult;
  // sub = getuserData["sub"];
  // exp = attempTime.add(Duration((seconds = getuserData["exp"])));
  // iat = attempTime.add(Duration((seconds = getuserData["iat"])));
  // var prefs = await SharedPreferences.getInstance();
  // var saveUserData = json.encode({
  //   token: tokenresult,
  //   sub: sub,
  //   exp: exp?.toIso8601String(),
  // });
  // prefs.setString("saveUserData", saveUserData);
  // var dataResultSuccess = {
  //   boolresult: true,
  //   bodyResult: resultLogin.body,
  // };
  // console.log("login result", resultLogin);
  // return saveUserData;
  // }
};

export const currentUser = () => (dispatch) => {
  if (localStorage.getItem("hastoken")) {
    dispatch({
      type: GET_CURRENT_USER,
      payload: jwtDecode(localStorage.getItem("token")),
    });
  } else {
    return;
  }
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const LoadUser = () => {
  let decodedUser = {};
  if (localStorage.getItem("token")) {
    decodedUser = jwtDecode(localStorage.getItem("token"));
  }

  return {
    type: LOAD_CURRENT_USER,
    payload: decodedUser,
  };
};
