import { GET_FETCH_SITE, POST_DATA } from "../actions/types";

const initialState = {
  data: {},
};
// 'https://api.mapbox.com/geocoding/v5/mapbox.places/${addressData}.json?access_token=pk.eyJ1Ijoic29ocmFiNDAiLCJhIjoiY2toazNwNTFjMHJxeDJ6c2R4eXRrMHNpayJ9.FcGKt0dYJvZ1K0w9LlrPhg'));

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FETCH_SITE:
      return {
        ...state,
        data: action.payload,
      };

    case POST_DATA:
      return {
        ...state,
        messageStatus: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
