import React, { Component } from "react";
import NormalTemplate from "../../templates/normal_template";
import FormBoxContactWidget from "../ui/form_box_contact";

const ContactPage = () => {
  return (
    <NormalTemplate
      navs={[
        {
          name: "home",
          routeName: "/",
        },
        {
          name: "about us",
          routeName: "/about",
        },
        {
          name: "services",
          routeName: "/services",
        },
        {
          name: "contact",
          routeName: "/contact",
          active: true,
        },
      ]}
      authenticated={false}
    >
      <section className="text-gray-600 body-font relative">
        <div className="absolute inset-0 bg-gray-300">
          <iframe
            width="100%"
            height="100%"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            title="map"
            scrolling="no"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52742.589622261214!2d-118.50980947606041!3d34.289047234757405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2905153bf48ef%3A0x4ba1e26d7d28b7d7!2sSan%20Fernando%2C%20CA!5e0!3m2!1sen!2sus!4v1700729126836!5m2!1sen!2sus"
            style={{ filter: "grayscale(1) contrast(1.2) opacity(0.4)" }}
          ></iframe>
        </div>
        <div className="container px-5 py-24 mx-auto flex">
          <div className="hidden lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8 flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md">
            <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
              Contact Form
            </h2>
            <p className="hidden leading-relaxed mb-5 text-gray-600">
              Post-ironic portland shabby chic echo park, banjo fashion axe
            </p>
            <div className="relative mb-4">
              <label for="email" className="leading-7 text-sm text-gray-600">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label for="message" className="leading-7 text-sm text-gray-600">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              ></textarea>
            </div>
            <button className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
              send
            </button>
            <p className="text-xs text-gray-500 mt-3">
              We will answer your question shortly
            </p>
          </div>

          <div className="lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md">
            <FormBoxContactWidget item={{}} />
          </div>
        </div>
      </section>
    </NormalTemplate>
  );
};

export default ContactPage;
