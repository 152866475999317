import React, { Component } from "react";
import { AboutSection } from "../index_components";
import NormalTemplate from "../../templates/normal_template";
import { Helmet } from "react-helmet";

const AboutPage = ({ site }) => {
  return (
    <>
      <Helmet>
        <meta name="description" content={site && site.content} />
        <title>About Us</title>
      </Helmet>
      <NormalTemplate
        navs={[
          {
            name: "home",
            routeName: "/",
          },
          {
            name: "about us",
            routeName: "/about",
            active: true,
          },
          {
            name: "services",
            routeName: "/services",
          },
          {
            name: "contact",
            routeName: "/contact",
          },
        ]}
        authenticated={false}
        showFooter={true}
      >
        <AboutSection site={site} />
      </NormalTemplate>
    </>
  );
};

export default AboutPage;
