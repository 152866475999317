import {
  FIND_ALL_ADDRESS,
  UPLOAD_PROCESS,
  FIND_ALL_PROJECTS_RELATED_USER,
  SINGLE_PROJECT,
} from "../actions/types";

const initialState = {
  addresses: [],
  uploadingProcess: 0,
  projects: [],
  editProject: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FIND_ALL_PROJECTS_RELATED_USER:
      return {
        ...state,
        projects: action.payload,
      };
    case FIND_ALL_ADDRESS:
      return {
        ...state,
        addresses: action.payload,
      };

    case SINGLE_PROJECT:
      return {
        ...state,
        editProject: action.payload,
      };

    case UPLOAD_PROCESS:
      return {
        ...state,
        uploadingProcess: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
