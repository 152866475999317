import {
  GET_CURRENT_USER,
  GET_FETCH_SITE,
  LOAD_CURRENT_USER,
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  currentUser: {},
  user: {},
  loggedUser: {},
  usersListed: [],
  data: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };

    case LOAD_CURRENT_USER:
      return {
        ...state,
        // isAuthenticated: !isEmpty(action.payload),
        loggedUser: action.payload,
      };

    case GET_FETCH_SITE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
