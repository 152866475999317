import React, { Component } from "react";

export default class VideoSection extends Component {
  render() {
    return (
      <>
        <div className="video wow fadeIn" data-wow-delay="0.1s">
          <div className="container">
            <button
              type="button"
              className="btn-play"
              data-toggle="modal"
              data-src="https://www.youtube.com/embed/bIImUAvOY2w?si=gkws3ARH5d1PwBAF"
              data-target="#videoModal"
            >
              <span></span>
            </button>
          </div>
        </div>

        <div
          className="modal fade"
          id="videoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog hidden sm:flex" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>

                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    title="iframe title"
                    className="embed-responsive-item"
                    src=""
                    id="video"
                    allowscriptaccess="always"
                    allow="autoplay"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>

          <div className="sm:hidden h-[40vh]">
            <iframe
              className="w-full h-full"
              src="https://www.youtube-nocookie.com/embed/bIImUAvOY2w?si=dAkiC5uoT5MGQAV3"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </>
    );
  }
}
