import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "boxicons";
import "../css/mainpart.css";
import ServiceItem from "../../service_item";
import { CheckIcon } from "lucide-react";
const exitFunction = () => {};

const showGallery = (beforeWorkGallery, galleryName) => {
  return (
    <>
      <div className="mt-10 flex items-center gap-x-4">
        <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
          What's included on {galleryName} of this project
        </h4>
        <div className="h-px flex-auto bg-gray-100" />
      </div>
      <ul
        role="list"
        className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6 md:grid-cols-3 md:gap-8 lg:grid-cols-4 lg:gap-10 xl:grid-cols-4 xl:gap-10"
      >
        {beforeWorkGallery &&
          beforeWorkGallery.map((feature) => (
            <li key={feature} className="flex gap-x-3">
              {/* <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          /> */}
              <div>
                <img
                  src={feature.itemSrc}
                  alt=""
                  className="aspect-square w-full"
                />
                {feature.content && (
                  <p className="line-clamp-4">{feature.content}</p>
                )}
              </div>
            </li>
          ))}
      </ul>
    </>
  );
};

const SingleProject = ({ projectItem }) => {
  var { thumbnail, projectName, details } = projectItem;
  var [projectItem, setProjectItem] = useState(projectItem);
  const [toggle, setToggle] = useState(false);
  const [editVersion, setEditVersion] = useState(false);
  const editProjectItem = (updates) => {
    setProjectItem((prevProjectItem) => ({
      ...prevProjectItem,
      ...updates,
    }));
  };
  const toggleMenu = () => {
    setToggle(!toggle);
  };
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="admin section of website |  users table section"
        />
        <title>Single project</title>

        {/* <link rel="canonical" href="http://example.com/example" /> */}
      </Helmet>
      <div className="relative">
        <nav className="h-14 transition-all delay-200 ease-in-out sticky top-0 z-50">
          <section className="bg-slate-300 hidden md:flex md:items-center h-full mx-auto max-w-7xl px-6 justify-between">
            <ul className="list-none flex flex-row gap-x-4 items-center">
              <li>
                <a className="text-2xl font-bold text-black" href="/">
                  Remodelchief
                </a>
              </li>
              <li>
                <a href="/dashboard/settings/createproject">create project</a>
              </li>
              <li>
                <a href="/dashboard/settings/users">users</a>
              </li>
              <li>
                <a href="/dashboard/settings/projects">projects</a>
              </li>
              <li>
                <a href="/dashboard/settings/themes">themes setting</a>
              </li>
            </ul>
            <ul>
              <li>
                <button>
                  <box-icon name="log-out"></box-icon>
                </button>
              </li>
            </ul>
          </section>
          <ul className="bg-slate-300 flex items-center md:hidden h-full mx-auto max-w-7xl px-6">
            <li>
              <button onClick={toggleMenu}>
                <box-icon name="menu-alt-left"></box-icon>
              </button>{" "}
            </li>
          </ul>
        </nav>
        {toggle && (
          <div className="flex md:hidden">
            <aside className="aside is-placed-left is-expanded">
              <div className="aside-tools">
                <a href="/">website</a>
                <span>/</span>
                <div>
                  <b className="font-black">Projects</b>
                </div>
              </div>
              <div className="menu is-menu-main">
                <p className="menu-label">General</p>
                <ul className="menu-list">
                  <li>
                    <a href="/dashboard">
                      <span className="icon">
                        <i className="mdi mdi-desktop-mac"></i>
                      </span>
                      <span className="menu-item-label">Dashboard</span>
                    </a>
                  </li>
                </ul>
                <p className="menu-label">Pages</p>
                <ul className="menu-list">
                  <li className="--set-active-tables-html">
                    <a href="/dashboard/settings/createproject">
                      <span className="icon">
                        <i className="mdi mdi-table"></i>
                      </span>
                      <span className="menu-item-label">Create a project</span>
                    </a>
                  </li>
                  <li>
                    <a href="/dashboard/settings/users">
                      <span className="icon">
                        <i className="mdi mdi-square-edit-outline"></i>
                      </span>
                      <span className="menu-item-label">Users</span>
                    </a>
                  </li>
                  <li className="active">
                    <a>
                      <span className="icon">
                        <i className="mdi mdi-account-circle"></i>
                      </span>
                      <span className="menu-item-label">projects</span>
                    </a>
                  </li>
                  <li>
                    <a href="/dashboard/settings/themes">
                      <span className="icon">
                        <i className="mdi mdi-lock"></i>
                      </span>
                      <span className="menu-item-label">Themes settings</span>
                    </a>
                  </li>
                </ul>
                <p className="menu-label">Actions</p>
                <ul className="menu-list">
                  <li>
                    <a
                      href="/dashboard/settings/header"
                      onclick="alert('Coming soon'); return false"
                      target="_blank"
                      className="has-icon"
                    >
                      <span className="icon">
                        <i className="mdi mdi-credit-card-outline"></i>
                      </span>
                      <span className="menu-item-label">Header section</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="/dashboard/settings/slides"
                      onclick="alert('Coming soon'); return false"
                      target="_blank"
                      className="has-icon"
                    >
                      <span className="icon">
                        <i className="mdi mdi-credit-card-outline"></i>
                      </span>
                      <span className="menu-item-label">Slide section</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="/dashboard/settings/footer"
                      onclick="alert('Coming soon'); return false"
                      target="_blank"
                      className="has-icon"
                    >
                      <span className="icon">
                        <i className="mdi mdi-credit-card-outline"></i>
                      </span>
                      <span className="menu-item-label">Footer section</span>
                    </a>
                  </li>
                </ul>
              </div>
            </aside>
          </div>
        )}
        {!toggle &&
          (!editVersion ? (
            <div className="md:flex bg-white py-24 sm:py-32">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto sm:text-center">
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {projectName}
                  </h2>
                  <div className="flex flex-col md:flex-row items-start w-full justify-between">
                    <div className="flex w-full md:w-[30%] rounded-xl border-4 items-center justify-center">
                      <img
                        src={projectItem.thumbnail}
                        alt=""
                        className="rounded-lg"
                      />
                    </div>

                    <p className="text-lg leading-8 text-gray-600 w-full md:w-[60%] flex justify-start">
                      {details}
                    </p>
                  </div>
                </div>
                <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
                  <div className="p-8 sm:p-10 lg:flex-auto bg-white">
                    <h3 className="text-2xl font-bold tracking-tight text-gray-900">
                      project details
                    </h3>
                    <div className="w-full">
                      <ul className="mt-6 text-base leading-7 text-black list-none">
                        <div className="flex flex-row">
                          <span>
                            <box-icon name="task"></box-icon>
                          </span>
                          <span>{projectItem.projectID}</span>
                        </div>
                        <li className="pr-4">
                          <div className="flex flex-row">
                            <box-icon name="user-circle"></box-icon>
                            <span>{projectItem.userName}</span>
                          </div>{" "}
                        </li>
                        <li>
                          <div className="flex flex-row">
                            <span>
                              <box-icon type="solid" name="envelope"></box-icon>
                            </span>
                            <span>{projectItem.managerEmail}</span>
                          </div>{" "}
                        </li>
                        <li>
                          <div className="flex flex-row">
                            <span>
                              <box-icon name="calendar"></box-icon>
                            </span>
                            <span>{projectItem.projectstartdate}</span>
                          </div>
                        </li>
                        <li>
                          <div className="flex flex-row">
                            <span>
                              <box-icon name="calendar"></box-icon>
                            </span>
                            <span>{projectItem.projectsfinishdate}</span>
                          </div>
                        </li>
                      </ul>

                      <div className="mt-4">
                        {projectItem.address && (
                          <div className="flex flex-row">
                            <span>
                              <box-icon type="solid" name="map"></box-icon>
                            </span>{" "}
                            <address>{projectItem.address.location}</address>
                          </div>
                        )}
                      </div>

                      <div className="mt-8">
                        <span className="text-black text-sm font-bold">
                          employees
                        </span>
                        <div class="flex -space-x-2 overflow-hidden">
                          <img
                            class="inline-block h-10 w-10 rounded-full ring-2 ring-white"
                            src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt=""
                          />
                          <img
                            class="inline-block h-10 w-10 rounded-full ring-2 ring-white"
                            src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt=""
                          />
                          <img
                            class="inline-block h-10 w-10 rounded-full ring-2 ring-white"
                            src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                            alt=""
                          />
                          <img
                            class="inline-block h-10 w-10 rounded-full ring-2 ring-white"
                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>

                    {/* locatio of before gallery old  */}
                  </div>
                  <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                    <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                      <div className="mx-auto max-w-xs px-8">
                        <p className="text-base font-semibold text-gray-600">
                          More details of project
                        </p>
                        <p className="mt-6 flex items-baseline justify-center gap-x-2">
                          <span className="text-5xl font-bold tracking-tight text-gray-900">
                            ${Number(projectItem.totalCostProject).toFixed(2)}
                          </span>
                          <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                            USD
                          </span>
                        </p>
                        <a
                          href="#"
                          className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          project type:{" "}
                          <span className="uppercase">{projectItem.type}</span>
                        </a>
                        <p className="mt-6 text-xs leading-5 text-gray-600">
                          <a
                            href="#"
                            className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            available days:{" "}
                            <span className="uppercase">
                              {projectItem.days.length} days
                            </span>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* new one */}
                {showGallery(projectItem.beforeWorkGallery, "before work")}
                {showGallery(projectItem.afterWorkGallery, "after work")}
                {showGallery(projectItem.materialsneeded, "invoice list")}
              </div>
            </div>
          ) : (
            <div>
              <div className="flex flex-col md:hidden">
                <div className="flex flex-col max-w-md mx-auto px-4 bg-red-500 w-full h-[calc(100vh-3.5rem)] overflow-y-scroll gap-y-4">
                  <span>project name</span>
                  <input
                    className="p-4 bg-white text-2xl font-bold"
                    name="projectName"
                  />
                  <span>description</span>
                  <input
                    className="p-4 bg-white text-2xl font-bold"
                    name="projectDescription"
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="admin section of website |  users table section"
        />
        <title>Users page</title>

        {/* <link rel="canonical" href="http://example.com/example" /> */}
      </Helmet>
      <div id="appadmin" className="flex justify-end">
        <aside className="aside is-placed-left is-expanded">
          <div className="aside-tools">
            <a href="/">website</a>
            <span>/</span>
            <div>
              <b className="font-black">Projects</b>
            </div>
          </div>
          <div className="menu is-menu-main">
            <p className="menu-label">General</p>
            <ul className="menu-list">
              <li>
                <a href="/dashboard">
                  <span className="icon">
                    <i className="mdi mdi-desktop-mac"></i>
                  </span>
                  <span className="menu-item-label">Dashboard</span>
                </a>
              </li>
            </ul>
            <p className="menu-label">Pages</p>
            <ul className="menu-list">
              <li className="--set-active-tables-html">
                <a href="/dashboard/settings/createproject">
                  <span className="icon">
                    <i className="mdi mdi-table"></i>
                  </span>
                  <span className="menu-item-label">Create a project</span>
                </a>
              </li>
              <li>
                <a href="/dashboard/settings/users">
                  <span className="icon">
                    <i className="mdi mdi-square-edit-outline"></i>
                  </span>
                  <span className="menu-item-label">Users</span>
                </a>
              </li>
              <li className="active">
                <a>
                  <span className="icon">
                    <i className="mdi mdi-account-circle"></i>
                  </span>
                  <span className="menu-item-label">projects</span>
                </a>
              </li>
              <li>
                <a href="/dashboard/settings/themes">
                  <span className="icon">
                    <i className="mdi mdi-lock"></i>
                  </span>
                  <span className="menu-item-label">Themes settings</span>
                </a>
              </li>
            </ul>
            <p className="menu-label">Actions</p>
            <ul className="menu-list">
              <li>
                <a
                  href="/dashboard/settings/header"
                  onclick="alert('Coming soon'); return false"
                  target="_blank"
                  className="has-icon"
                >
                  <span className="icon">
                    <i className="mdi mdi-credit-card-outline"></i>
                  </span>
                  <span className="menu-item-label">Header section</span>
                </a>
              </li>
              <li>
                <a
                  href="/dashboard/settings/slides"
                  onclick="alert('Coming soon'); return false"
                  target="_blank"
                  className="has-icon"
                >
                  <span className="icon">
                    <i className="mdi mdi-credit-card-outline"></i>
                  </span>
                  <span className="menu-item-label">Slide section</span>
                </a>
              </li>
              <li>
                <a
                  href="/dashboard/settings/footer"
                  onclick="alert('Coming soon'); return false"
                  target="_blank"
                  className="has-icon"
                >
                  <span className="icon">
                    <i className="mdi mdi-credit-card-outline"></i>
                  </span>
                  <span className="menu-item-label">Footer section</span>
                </a>
              </li>
            </ul>
          </div>
        </aside>
        <section className="section main-section w-full lg:w-[calc(100vw-15rem)] hidden">
          <div>
            <div className="w-full h-[3.5rem] mb-4 bgdashboard items-center flex justify-end">
              <div className="w-[60px] bg-yellow-400 h-full flex items-center justify-around">
                <button onClick={exitFunction}>
                  <span>
                    <i className="fas fa-sign-out-alt"></i>
                  </span>
                </button>
              </div>
            </div>
            <div className="grid gap-6 grid-cols-1 md:grid-cols-3 mb-6 p-4">
              <div className="card">
                <div className="card-content">
                  <div className="flex items-center justify-between">
                    <div className="widget-label">
                      <h2>projects</h2>
                      <h1>2</h1>
                    </div>
                    <span className="icon widget-icon text-green-500">
                      <i className="mdi mdi-account-multiple mdi-48px"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-content">
                  <div className="flex items-center justify-between">
                    <div className="widget-label">
                      <h3>Sales</h3>
                      <h1>$1,500</h1>
                    </div>
                    <span className="icon widget-icon text-blue-500">
                      <i className="mdi mdi-cart-outline mdi-48px"></i>
                    </span>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-content">
                  <div className="flex items-center justify-between">
                    <div className="widget-label">
                      <h3>User position</h3>
                      <h1>Admin</h1>
                    </div>
                    <span className="icon widget-icon text-red-500">
                      <i className="mdi mdi-finance mdi-48px"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card has-table p-4">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-multiple"></i>
                </span>
                projects
              </p>
              <a href="#" className="card-header-icon">
                <span className="icon">
                  <i className="mdi mdi-reload"></i>
                </span>
              </a>
            </header>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {projectName}
            </h2>
            <div className="card-content">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>address</th>
                    <th>Costs</th>
                    <th>Created</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Name">Rebecca Bauch</td>
                    <td data-label="Company">Daugherty-Daniel</td>
                    <td data-label="Costs">$0.00</td>
                    <td data-label="Created">
                      <small
                        className="text-gray-500"
                        title={new Date(Date.now()).toLocaleDateString()}
                      >
                        {new Date(Date.now()).toLocaleDateString()}
                      </small>
                    </td>
                    <td className="actions-cell">
                      <div className="buttons right nowrap">
                        <button
                          className="button small green --jb-modal"
                          data-target="sample-modal-2"
                          type="button"
                        >
                          <span className="icon">
                            <i className="mdi mdi-eye"></i>
                          </span>
                        </button>
                        <button
                          className="button small red --jb-modal"
                          data-target="sample-modal"
                          type="button"
                        >
                          <span className="icon">
                            <i className="mdi mdi-trash-can"></i>
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Name">Felicita Yundt</td>
                    <td data-label="Company">Johns-Weissnat</td>
                    <td data-label="Costs">$0.00</td>
                    <td data-label="Created">
                      <small
                        className="text-gray-500"
                        title={new Date(Date.now()).toLocaleDateString()}
                      >
                        {new Date(Date.now()).toLocaleDateString()}
                      </small>
                    </td>
                    <td className="actions-cell">
                      <div className="buttons right nowrap">
                        <button
                          className="button small green --jb-modal"
                          data-target="sample-modal-2"
                          type="button"
                        >
                          <span className="icon">
                            <i className="mdi mdi-eye"></i>
                          </span>
                        </button>
                        <button
                          className="button small red --jb-modal"
                          data-target="sample-modal"
                          type="button"
                        >
                          <span className="icon">
                            <i className="mdi mdi-trash-can"></i>
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="table-pagination">
                <div className="flex items-center justify-between">
                  <div className="buttons">
                    <button type="button" className="button active">
                      1
                    </button>
                    <button type="button" className="button">
                      2
                    </button>
                    <button type="button" className="button">
                      3
                    </button>
                  </div>
                  <small>Page 1 of 3</small>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section main-section w-full lg:w-[calc(100vw-15rem)]">
          <div className="sticky top-0 z-50">
            <div className="w-full h-[3.5rem] mb-4 bgdashboard items-center flex justify-end">
              <div className="w-[60px] bg-yellow-400 h-full flex items-center justify-around">
                <button onClick={exitFunction}>
                  <span>
                    <i className="fas fa-sign-out-alt"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className="card has-table p-4">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-multiple"></i>
                </span>
                Project /{projectItem.projectID}
              </p>

              <a href="#" className="card-header-icon">
                <span className="icon">
                  <i className="mdi mdi-reload"></i>
                </span>
              </a>
            </header>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {projectName}
            </h2>
            <div className="flex row p-3 justify-between">
              <img
                src={thumbnail}
                alt={projectName}
                className="h-48 w-48 rounded-lg"
              />
              <div className="w-full sm:max-w-2xl">
                <p className="">{details}</p>
              </div>
            </div>

            <div className="card-content hidden">
              <table>
                <thead>
                  <tr>
                    <th>Thumbnail</th>
                    <th>Name</th>
                    <th>Details</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
              <div className="table-pagination hidden">
                <div className="flex items-center justify-between">
                  <div className="buttons">
                    <button type="button" className="button active">
                      1
                    </button>
                    <button type="button" className="button">
                      2
                    </button>
                    <button type="button" className="button">
                      3
                    </button>
                  </div>
                  <small>Page 1 of 3</small>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SingleProject;
