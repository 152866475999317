import React, { Component } from "react";
import "./css/mainpart.css";
import { Helmet } from "react-helmet";
import DashboardTemplate from "../../templates/dashboard_template";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";

export default class DashboardPage extends Component {
  constructor() {
    super();
  }
  render() {
    return (
      <DashboardTemplate
        termName="dashboard"
        termActive={true}
        termLink={`/dashboard`}
        items={[
          {
            name: "new project",
            itemLink: `/dashboard/settings/createproject`,
          },
          {
            name: "users",
            itemLink: `/dashboard/settings/users`,
          },
          {
            name: "projects",
            itemLink: `/dashboard/settings/projects`,
          },
          // {
          //   name: "themes settings",
          //   itemLink: `/dashboard/settings/themes`,
          // },
        ]}
        contentData={
          <div className="p-4">
            <Accordion type="single" collapsible className="w-full">
              <AccordionItem value="item-1">
                <AccordionTrigger>Is it accessible?</AccordionTrigger>
                <AccordionContent>
                  Yes. It adheres to the WAI-ARIA design pattern.
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-2">
                <AccordionTrigger>Is it styled?</AccordionTrigger>
                <AccordionContent>
                  Yes. It comes with default styles that matches the other
                  components&apos; aesthetic.
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-3">
                <AccordionTrigger>Is it animated?</AccordionTrigger>
                <AccordionContent>
                  Yes. It's animated by default, but you can disable it if you
                  prefer.
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        }
        seo={
          <Helmet>
            <meta name="description" content="admin section of website" />
            <title>My Dashboard page</title>
          </Helmet>
        }
      />
    );
  }

  renderold() {
    var { toggle } = this.state;
    return (
      <>
        <Helmet>
          <meta name="description" content="admin section of website" />
          <title>My Dashboard page</title>

          {/* <link rel="canonical" href="http://example.com/example" /> */}
        </Helmet>
        <div className="h-[100vh] flex w-full ">
          <div className="sm:hidden overflow-y-scroll w-full bg-white">
            <div className="p-4 h-14 transition-all delay-200 ease-in-out sticky top-0 z-50 bg-sky-900 items-center flex text-white justify-between">
              <button onClick={this.toggleMenu}>
                {!toggle ? (
                  <box-icon
                    name="menu-alt-left"
                    color="white"
                    pull="right"
                    animation="tada"
                  />
                ) : (
                  <box-icon name="menu-alt-left" color="white" />
                )}
              </button>
              {this.AlertLogout()}
            </div>
            {!toggle ? (
              <div className="w-full bg-white">{this.contentData()}</div>
            ) : (
              <div className="w-full h-[calc(100vh-3.5rem)]" id="appadmin">
                {this.sideBar()}
              </div>
            )}
          </div>

          <div className="hidden sm:flex md:hidden w-full">
            <div className="w-full bg-white flex-col overflow-y-scroll">
              <div className="p-4 h-14 transition-all delay-200 ease-in-out sticky top-0 z-50 bg-sky-900 items-center flex text-white justify-between">
                <button onClick={this.toggleMenu}>
                  {!toggle ? (
                    <box-icon
                      name="menu-alt-left"
                      color="white"
                      pull="right"
                      animation="tada"
                    />
                  ) : (
                    <box-icon name="menu-alt-left" color="white" />
                  )}
                </button>
                <section>{this.AlertLogout()}</section>
              </div>
              {!toggle ? (
                this.contentData()
              ) : (
                <div className="w-full h-[calc(100vh-3.5rem)]" id="appadmin">
                  {this.sideBar()}
                </div>
              )}
            </div>
          </div>

          <div
            id="appadmin"
            className="hidden md:flex md:flex-row w-full lg:hidden"
          >
            <div className="h-full bg-slate-600 w-[15rem] text-sm transition-all duration-700 ease-in-out">
              {this.sideBar()}
            </div>
            <div className="w-[calc(100vw-15rem)] bg-white flex-col overflow-y-scroll">
              {this.menuBar()}
              {this.contentData()}
            </div>
          </div>
          <div id="appadmin" className="hidden lg:flex xl:hidden">
            <div className="h-full bg-slate-600 w-[15rem] text-sm transition-all duration-700 ease-in-out">
              {this.sideBar()}
            </div>
            <div className="w-[calc(100vw-15rem)] bg-white flex-col overflow-y-scroll">
              {this.menuBar()}
              {this.contentData()}
            </div>
          </div>
          <div id="appadmin" className="hidden xl:flex">
            <div className="h-full bg-slate-600 w-[15rem] text-sm transition-all duration-700 ease-in-out">
              {this.sideBar()}
            </div>
            <div className="w-[calc(100vw-15rem)] bg-white flex-col overflow-y-scroll">
              {this.menuBar()}
              {this.contentData()}
            </div>
          </div>
        </div>
      </>
    );
  }
}
