import React, { Component } from "react";
import { connect } from "react-redux";
import { singInFunction, currentUser } from "../../actions/auth_actions";
import { Navigate } from "react-router";
import FormBoxLoginWidget from "../ui/form_box_login";

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: {},
      emailAddress: "",
      password: "",
      formSubmitting: false,

      formProcessing: false,
      readytogo: false,
      formResult: false,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    this.props.currentUser();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log("auth props we receive from function", nextProps);

    if (nextProps.auth !== prevState.auth) {
      if (nextProps.auth.currentUser.sub) {
        window.location.href = "/dashboard";
      }

      return { auth: nextProps.auth };
    }
    return null;
  }

  submitButton = async (e) => {
    e.preventDefault();
    // form is ging to process
    this.setState({ formProcessing: true });
    const { emailAddress, password } = this.state;
    // function is called

    if (!emailAddress || !password) return;
    this.setState({ functionCalled: true });
    await singInFunction(emailAddress, password);
    this.setState({ functionCalled: false });
    // result must be rendered

    this.setState({ resultShow: true });
    // if token exist , setstate token exist so immediatlt navigate to dashboard

    if (localStorage.getItem("token")) {
      window.location.href = "/dashboard";

      return;
    } else {
      // form process finished successfully
      this.setState({ formProcessing: false });

      return;
    }
  };

  render() {
    return (
      <>
        <FormBoxLoginWidget />

        
      </>
    );
  }

  renderoldversiontru() {
    var { formProcessing, functionCalled, resultShow } = this.state;
    return !formProcessing ? (
      <form className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <a href="/">
            <img
              className="mx-auto h-10 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              alt="Your Company"
            />
          </a>

          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" action="#" method="POST">
            <div>
              <label
                for="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  onChange={this.onChange}
                  value={this.state.emailAddress}
                  id="email"
                  name="emailAddress"
                  type="email"
                  autocomplete="email"
                  required
                  className="block h-[60px] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  for="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="text-sm">
                  <a
                    href="#"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className="mt-2">
                <input
                  onChange={this.onChange}
                  value={this.state.password}
                  id="password"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  required
                  className="h-[60px] block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                onClick={this.submitButton}
                className="h-[60px] flex w-full justify-center items-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in {String(formProcessing)}
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?
            <a
              href="/register"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Register your account
            </a>
          </p>
        </div>
      </form>
    ) : functionCalled ? (
      <div>loading...</div>
    ) : resultShow ? (
      localStorage.getItem("token") != undefined ? (
        <div>we have token </div>
      ) : (
        <div>form finished </div>
      )
    ) : (
      <div>form function called and finished</div>
    );
  }

  renderold() {
    var { auth, formSubmitting, readytogo, formResult } = this.state;

    return !formResult ? (
      <div className="p-10 sm:p-0 md:p-0 lg:p-0 xl:p-0">
        <form className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <a href="/">
              <img
                className="mx-auto h-10 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt="Your Company"
              />
            </a>

            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Sign in to your account
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label
                  for="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    onChange={this.onChange}
                    value={this.state.emailAddress}
                    id="email"
                    name="emailAddress"
                    type="email"
                    autocomplete="email"
                    required
                    className="block h-[60px] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    for="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="text-sm">
                    <a
                      href="#"
                      className="font-semibold text-indigo-600 hover:text-indigo-500"
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>
                <div className="mt-2">
                  <input
                    onChange={this.onChange}
                    value={this.state.password}
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    required
                    className="h-[60px] block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                {!readytogo && !formSubmitting ? (
                  <button
                    onClick={this.submitButton}
                    className="h-[60px] flex w-full justify-center items-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Sign in
                  </button>
                ) : (
                  <span className="h-[60px] flex w-full justify-center items-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    loading ...
                  </span>
                )}
              </div>
            </form>

            <p className="mt-10 text-center text-sm text-gray-500">
              Not a member?
              <a
                href="/register"
                className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
              >
                Register your account
              </a>
            </p>
          </div>
        </form>
      </div>
    ) : formResult ? (
      <Navigate to="/dashboard" replace />
    ) : (
      <div>laoding ....</div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps, {
  singInFunction,
  currentUser,
})(LoginPage);
