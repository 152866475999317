import React, { Component } from "react";

import { connect } from "react-redux";
import {
  CarouselSection,
  FeautureSection,
  AboutSection,
  InfoBanner,
  ServiceSection,
  VideoSection,
  TeamSection,
  ProjectsSection,
  FaqSection,
} from "../../components/index_components";
import NormalTemplate from "../../templates/normal_template";
// team
import t1 from "../../img/team-1.jpg";
import t3 from "../../img/team-3.jpg";
// project section
import b1 from "../../img/blog-1.jpg";
import b2 from "../../img/blog-2.jpg";
import b3 from "../../img/blog-3.jpg";
import { fetchData } from "../../actions/site_action";
const MainPage = ({ services, site }) => {
  return (
    <NormalTemplate
      navs={[
        {
          name: "home",
          routeName: "/",
          active: true,
        },
        {
          name: "about us",
          routeName: "/about",
        },
        {
          name: "services",
          routeName: "/services",
        },
        {
          name: "contact",
          routeName: "/contact",
        },
      ]}
      authenticated={false}
      showFooter={true}
      services={services}
    >
      <CarouselSection sliders={services} />
      <FeautureSection />
      <AboutSection site={site} />
      <InfoBanner banner={{}} />
      <ServiceSection services={services} />
      <VideoSection />

      {/* <TeamSection
        team={[
          {
            name: "Sohrab Ahmadi",
            position: "CEO and Founder",
            image: t1,
          },
        ]}
      /> */}
      {/* <FaqSection faqsColLeft={[]} faqsColRight={[]} /> */}
      {/* <ProjectsSection
        projects={[
          {
            name: "project construction",
            image: b1,
            type: "Construction",
            content: "",
          },
          {
            name: "project construction2",
            image: b2,
            type: "Bath",
            content: "",
          },
          {
            name: "project construction3",
            image: b3,
            type: "Renovating",
            content: "",
          },
          {
            name: "project construction",
            image: b2,
            type: "Bath",
            content: "",
          },
          {
            name: "project construction",
            image: b3,
            type: "Renovating",
            content: "",
          },
        ]}
      /> */}
    </NormalTemplate>
  );
};

export default MainPage;
