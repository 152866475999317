import React, { Component } from "react";
import "../components/dashboardpage/css/mainpart.css";
import { Helmet } from "react-helmet";
import { Rows } from "lucide-react";
import "boxicons";
import * as Avatar from "@radix-ui/react-avatar";
import { SideBar } from "./sidebar_template";
import { ContentBox } from "./content_template";

import { RocketIcon } from "@radix-ui/react-icons";

// import { Alert, AlertDescription, AlertTitle } from "";
import { Alert, AlertTitle, AlertDescription } from "../components/ui/alert";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../components/ui/alert-dialog";

export default class DashboardTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
    };
  }
  AlertLogout() {
    return (
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <span variant="outline" className="bg-red-400 rounded-sm p-2">
            <Avatar.Root className="AvatarRoot">
              <Avatar.Fallback className="AvatarFallback">RC</Avatar.Fallback>
            </Avatar.Root>
          </span>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure to log out?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <div className="items-center flex h-20 justify-around">
            <span>
              <AlertDialogCancel className="p-2 m-0">cancel</AlertDialogCancel>
            </span>
            <span>
              <AlertDialogAction
                className="p-2 m-0"
                onClick={this.exitFunction}
              >
                log out
              </AlertDialogAction>
            </span>

            {/* <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction>Continue</AlertDialogAction> */}
          </div>
        </AlertDialogContent>
      </AlertDialog>
    );
    // return (
    //   <Alert>
    //     {/* <RocketIcon className="h-4 w-4" /> */}
    //     <AlertTitle>Heads up!</AlertTitle>
    //     <AlertDescription>
    //       You can add components to your app using the cli.
    //     </AlertDescription>
    //   </Alert>
    // );
  }
  exitFunction = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  toggleMenu = () => {
    this.setState({ toggle: !this.state.toggle });
  };
  menuBar() {
    return (
      <div className="px-4 h-[3.5rem] top-0 sticky z-50 bg-sky-900 items-center flex text-white justify-between">
        <section>Remodelchief</section>
        <span className="text-2xl font-semibold">{this.AlertLogout()}</span>
      </div>
    );
  }

  sideBar() {
    var { termName, termActive, termLink, items } = this.props;
    return (
      <SideBar
        termName={termName}
        termActive={termActive}
        termLink={termLink}
        items={items}
      />
    );
    return (
      <aside className="bg-slate-600 h-full">
        <div className="aside-tools">
          <a href="/">website</a>
          <span>/</span>
          <div>
            <b className="font-black">Dashboard</b>
          </div>
        </div>
        <div className="menu is-menu-main">
          <p className="menu-label">General</p>
          <ul className="menu-list">
            <li className="active">
              <a href="/dashboard">
                <span className="icon">
                  <i className="mdi mdi-desktop-mac"></i>
                </span>
                <span className="menu-item-label">Dashboard</span>
              </a>
            </li>
          </ul>
          <p className="menu-label">Pages</p>
          <ul className="menu-list">
            <li className="--set-active-tables-html">
              <a href="/dashboard/settings/createproject">
                <span className="icon">
                  <i className="mdi mdi-table"></i>
                </span>
                <span className="menu-item-label">Create a project</span>
              </a>
            </li>
            <li>
              <a href="/dashboard/settings/users">
                <span className="icon">
                  <i className="mdi mdi-square-edit-outline"></i>
                </span>
                <span className="menu-item-label">Users</span>
              </a>
            </li>
            <li className="--set-active-profile-html">
              <a href="/dashboard/settings/projects">
                <span className="icon">
                  <i className="mdi mdi-account-circle"></i>
                </span>
                <span className="menu-item-label">Projects</span>
              </a>
            </li>
            <li>
              <a href="/dashboard/settings/themes">
                <span className="icon">
                  <i className="mdi mdi-lock"></i>
                </span>
                <span className="menu-item-label">Themes settings</span>
              </a>
            </li>
          </ul>
          <p className="menu-label">Actions</p>
          <ul className="menu-list">
            <li>
              <a
                href="/dashboard/settings/header"
                onclick="alert('Coming soon'); return false"
                target="_blank"
                className="has-icon"
              >
                <span className="icon">
                  <i className="mdi mdi-credit-card-outline"></i>
                </span>
                <span className="menu-item-label">Header section</span>
              </a>
            </li>
            <li>
              <a
                href="/dashboard/settings/slides"
                onclick="alert('Coming soon'); return false"
                target="_blank"
                className="has-icon"
              >
                <span className="icon">
                  <i className="mdi mdi-credit-card-outline"></i>
                </span>
                <span className="menu-item-label">Slide section</span>
              </a>
            </li>
            <li>
              <a
                href="/dashboard/settings/footer"
                onclick="alert('Coming soon'); return false"
                target="_blank"
                className="has-icon"
              >
                <span className="icon">
                  <i className="mdi mdi-credit-card-outline"></i>
                </span>
                <span className="menu-item-label">Footer section</span>
              </a>
            </li>
          </ul>
        </div>
      </aside>
    );
  }

  contentData() {
    var { contentData } = this.props;
    return <ContentBox data={contentData} />;
  }

  render() {
    var { toggle } = this.state;
    var { seo } = this.props;
    return (
      <>
        {seo}
        <div className="h-[100vh] flex w-full ">
          <div className="sm:hidden overflow-y-scroll w-full bg-white">
            <div className="p-4 h-14 transition-all delay-200 ease-in-out sticky top-0 z-50 bg-sky-900 items-center flex text-white justify-between">
              <button onClick={this.toggleMenu}>
                {!toggle ? (
                  <box-icon
                    name="menu-alt-left"
                    color="white"
                    pull="right"
                    animation="tada"
                  />
                ) : (
                  <box-icon name="menu-alt-left" color="white" />
                )}
              </button>
              {this.AlertLogout()}
            </div>
            {!toggle ? (
              <div className="w-full bg-white">{this.contentData()}</div>
            ) : (
              <div className="w-full h-[calc(100vh-3.5rem)]" id="appadmin">
                {this.sideBar()}
              </div>
            )}
          </div>

          <div className="hidden sm:flex md:hidden w-full">
            <div className="w-full bg-white flex-col overflow-y-scroll">
              <div className="p-4 h-14 transition-all delay-200 ease-in-out sticky top-0 z-50 bg-sky-900 items-center flex text-white justify-between">
                <button onClick={this.toggleMenu}>
                  {!toggle ? (
                    <box-icon
                      name="menu-alt-left"
                      color="white"
                      pull="right"
                      animation="tada"
                    />
                  ) : (
                    <box-icon name="menu-alt-left" color="white" />
                  )}
                </button>
                <section>{this.AlertLogout()}</section>
              </div>
              {!toggle ? (
                this.contentData()
              ) : (
                <div className="w-full h-[calc(100vh-3.5rem)]" id="appadmin">
                  {this.sideBar()}
                </div>
              )}
            </div>
          </div>

          <div
            id="appadmin"
            className="hidden md:flex md:flex-row w-full lg:hidden"
          >
            <div className="h-full bg-slate-600 w-[15rem] text-sm transition-all duration-700 ease-in-out">
              {this.sideBar()}
            </div>
            <div className="w-[calc(100vw-15rem)] bg-white flex-col overflow-y-scroll">
              {this.menuBar()}
              {this.contentData()}
            </div>
          </div>
          <div id="appadmin" className="hidden lg:flex xl:hidden">
            <div className="h-full bg-slate-600 w-[15rem] text-sm transition-all duration-700 ease-in-out">
              {this.sideBar()}
            </div>
            <div className="w-[calc(100vw-15rem)] bg-white flex-col overflow-y-scroll">
              {this.menuBar()}
              {this.contentData()}
            </div>
          </div>
          <div id="appadmin" className="hidden xl:flex">
            <div className="h-full bg-slate-600 w-[15rem] text-sm transition-all duration-700 ease-in-out">
              {this.sideBar()}
            </div>
            <div className="w-[calc(100vw-15rem)] bg-white flex-col overflow-y-scroll">
              {this.menuBar()}
              {this.contentData()}
            </div>
          </div>
        </div>
      </>
    );
  }
}
