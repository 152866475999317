"use client";

import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import parsePhoneNumberFromString from 'libphonenumber-js';
import { Button } from "../ui/button";
import {postData} from "../../actions/site_action";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../ui/alert-dialog";

// Import statements remain the same


export const zPhone = z.string().transform((arg, ctx) => {
  const phone = parsePhoneNumberFromString(arg, {
    // set this to use a default country when the phone number omits country code
    defaultCountry: 'US',
    
    // set to false to require that the whole string is exactly a phone number,
    // otherwise, it will search for a phone number anywhere within the string
    extract: false,
  });

  // when it's good
  if (phone && phone.isValid()) {
    return phone.number;
  }

  // when it's not
  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    message: 'Invalid phone number',
  });
  return z.NEVER;
});


const FormBoxWidget = ({ item }) => {
  const formSchema = z.object({
    fullName: z.string().min(2, {
      message: "name required for getting quote of " + item.name,
    }),
    email: z.string().min(2, {
      message: "Email required for getting quote of " + item.name,
    }).email({message:"This is not a valid email."}),
    phone:zPhone

    // phone: z.string().min(10, {
    //   message: "phone required for getting quote of " + item.name,
    // }),


  
  });

  // 1. Define your form.
  const form = useForm({
    mode: "onSubmit",
    resolver: zodResolver(formSchema),
    defaultValues: {
      fullName: "",
      email: "",
      phone: "",
    },
  });

  // 2. Define a submit handler.
  function onSubmit(values) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    


    var dataforSave = {...values, ...item};
var dataObj= {
  description :"service page",
  emailCustomer : dataforSave.email,
  fullName : dataforSave.fullName,
  itemSrc : dataforSave.itemSrc,
  quoteTopic : dataforSave.name,
  phoneCustomer : dataforSave.phone,
  serviceId:dataforSave.serviceId,
  whatsIncluded:dataforSave.whatsIncluded,
};

    postData(dataObj);

    form.reset({
      phone:"+1"
    });


  }

  function onResetFunc() {
    console.log("form reset");

    
    form.reset({
      phone:"+1"
    });
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        onReset={onResetFunc}
        className="space-y-4 flex-col justify-start w-full"
      >
        <FormField
          control={form.control}
          name="fullName"
          render={({ field }) => (
            <FormItem>
              {/* <FormLabel>Your email</FormLabel> */}
              <FormControl>
                <Input placeholder="full name" {...field} className="h-[3.5rem]" />
              </FormControl>
              {/* <FormDescription>please type your email.</FormDescription> */}
              <FormMessage className="flex justify-start py-4" />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              {/* <FormLabel>Your email</FormLabel> */}
              <FormControl>
                <Input placeholder="email" {...field} className="h-[3.5rem]" />
              </FormControl>
              {/* <FormDescription>please type your email.</FormDescription> */}
              <FormMessage className="flex justify-start py-4" />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          className="two"
          name="phone"
          render={({ field }) => (
            <FormItem className="flex-col justify-start w-full">
              {/* <FormLabel>Your email</FormLabel> */}
              <FormControl className="here">
                {/* <Input type="number" placeholder="phone" {...field} className="h-[3.5rem]"/> */}

                <PhoneInput
                  {...field}
                  specialLabel=""
                  country={"us"}

                  // onChange={(phone) =>

                  // }
                />
              </FormControl>
              {/* <FormDescription>please type your email.</FormDescription> */}
              <FormMessage className="flex justify-start py-4" />
            </FormItem>
          )}
        />

        <div className="flex justify-between">
          <Button type="reset">reset quote</Button>
          <Button type="submit">Submit quote</Button>
        </div>
      </form>
    </Form>
  );
};

export default FormBoxWidget;
