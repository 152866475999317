import React from "react";
import { SideBar } from "../../../templates/sidebar_template";

const AsideComponent = () => {
  return (
    <SideBar
      orgClass={true}
      termName="dashboard"
      termActive={false}
      termLink={`/dashboard`}
      items={[
        {
          active: true,
          name: "new project",
          itemLink: `/dashboard/settings/createproject`,
        },
        {
          name: "users",
          itemLink: `/dashboard/settings/users`,
        },
        {
          name: "projects",
          itemLink: `/dashboard/settings/projects`,
        },
        {
          name: "themes settings",
          itemLink: `/dashboard/settings/themes`,
        },
      ]}
    />
  );
  return (
    <aside className="aside is-placed-left is-expanded">
      <div className="aside-tools">
        <a href="/">website</a>
        <span>/</span>
        <div>
          <b className="font-black">Create a Project</b>
        </div>
      </div>
      <div className="menu is-menu-main">
        <p className="menu-label">General</p>
        <ul className="menu-list">
          <li>
            <a href="/dashboard">
              <span className="icon">
                <i className="mdi mdi-desktop-mac"></i>
              </span>
              <span className="menu-item-label">Dashboard</span>
            </a>
          </li>
        </ul>
        <p className="menu-label">Pages</p>
        <ul className="menu-list">
          <li className="active">
            <a>
              <span className="icon">
                <i className="mdi mdi-table"></i>
              </span>
              <span className="menu-item-label">Create a project</span>
            </a>
          </li>
          <li className="--set-active-forms-html">
            <a href="/dashboard/settings/users">
              <span className="icon">
                <i className="mdi mdi-square-edit-outline"></i>
              </span>
              <span className="menu-item-label">Users</span>
            </a>
          </li>
          <li className="--set-active-profile-html">
            <a href="/dashboard/settings/projects">
              <span className="icon">
                <i className="mdi mdi-account-circle"></i>
              </span>
              <span className="menu-item-label">Projects</span>
            </a>
          </li>
          <li>
            <a href="/dashboard/settings/themes">
              <span className="icon">
                <i className="mdi mdi-lock"></i>
              </span>
              <span className="menu-item-label">Themes settings</span>
            </a>
          </li>
        </ul>
        <p className="menu-label">Actions</p>
        <ul className="menu-list">
          <li>
            <a
              href="/dashboard/settings/header"
              target="_blank"
              className="has-icon"
            >
              <span className="icon">
                <i className="mdi mdi-credit-card-outline"></i>
              </span>
              <span className="menu-item-label">Header section</span>
            </a>
          </li>
          <li>
            <a
              href="/dashboard/settings/slides"
              target="_blank"
              className="has-icon"
            >
              <span className="icon">
                <i className="mdi mdi-credit-card-outline"></i>
              </span>
              <span className="menu-item-label">Slide section</span>
            </a>
          </li>
          <li>
            <a
              href="/dashboard/settings/footer"
              target="_blank"
              className="has-icon"
            >
              <span className="icon">
                <i className="mdi mdi-credit-card-outline"></i>
              </span>
              <span className="menu-item-label">Footer section</span>
            </a>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default AsideComponent;
