import React, { Component } from "react";

import {
  HeaderSection,
  FooterSection,
  ScrollUp,
} from "../components/index_components";
export default class NormalTemplate extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    const { navs, authenticated, children, showFooter, services } = this.props;

    return (
      <div className="wrapper min-h-screen">
        <HeaderSection navs={navs} authenticated={authenticated} />
        {children}
        {showFooter && <FooterSection services={services} />}
        <ScrollUp />
      </div>
    );
  }
}
