import { useState, useEffect } from "react";

const ImageWithSkeleton = ({ src, alt, index, className }) => {
  const [loading, setLoading] = useState(true);

  //   useEffect(() => {
  //     const img = new Image();
  //     img.src = src;

  //     // img.onload = () => {
  //     //   setTimeout(() => {
  //     //     setLoading(false);
  //     //   }, 1000 * index);
  //     // };

  //     return () => {
  //       // Clean up to prevent memory leaks
  //       img.onload = null;
  //     };
  //   }, [src]);

  return (
    <div>
      {/* {!loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "#C80F0F", // Set a background color or use a loading animation
          }}
        />
      )} */}
      <img
        src={src}
        alt={alt}
        className={className}
        onLoad={() => {
          console.log("pic loading");
        }}
        onProgress={() => {
          console.log("pic progress");
        }}
      />
    </div>
  );
};

export default ImageWithSkeleton;
