import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
  } from "../ui/alert-dialog"


  import {
    Button
  } from "./button";
  
  export function AlertDialogBox({title, description, actionCall, actionCancel,className}) {
    return (
      <AlertDialog className="z-[80]">
        <AlertDialogTrigger asChild>
          <Button variant="outline" className={className}>{title}</Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>


          <div className="w-full flex justify-end h-auto">

            <div className="bg-red-600 p-1">
            <AlertDialogCancel>
              exit
            </AlertDialogCancel>
            </div>
          </div>

            <AlertDialogTitle>{title}</AlertDialogTitle>
            <AlertDialogDescription>
              {description}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            {actionCancel && <AlertDialogCancel>{actionCancel}</AlertDialogCancel>}
            {actionCall && <AlertDialogAction>{actionCall}</AlertDialogAction>}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }
  