import { json } from "react-router-dom";
import { GET_FETCH_SITE, POST_DATA } from "./types";
import axios from "axios";
import Swal from "sweetalert2";

export const fetchData = () => async (dispatch) => {
  await axios
    .get("https://a5hjwpyn54.execute-api.us-east-2.amazonaws.com/demo/info")
    .then((res) => {
      dispatch({
        type: GET_FETCH_SITE,
        payload: res.data,
      });
    })
    .catch((err) => console.error(err));
};

export const postData = (formData) => {
  console.log("we came here finally");

  // Swal.fire({
  //   title: "Good job!",
  //   text: "You clicked the button!",
  //   icon: "success",
  // });
  axios
    .post(
      "https://a5hjwpyn54.execute-api.us-east-2.amazonaws.com/demo/sentEmail",
      formData
    )
    .then(
      (res) => console.log("successfully done")
      // Swal.fire({
      //   title: "Good job!",
      //   text: "You clicked the button!",
      //   icon: "success",
      // })
    )
    .catch((e) => console.error(e));
};
