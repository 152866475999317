import React, { Component } from "react";
import SkeletonFrame from "../../skeleton_frame";
import Skeleton from "react-loading-skeleton";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import {
  findAllAddresses,
  uploadImage,
  singleProject,
  // uploadingProcess,
} from "../../../actions/project_actions";
import axios from "axios";
import AsideComponent from "./aside_component";
import PreviewProject from "./preview_project";
import UploadBar from "./upload_bar";
import { redirect } from "react-router-dom";
import DashboardTemplate from "../../../templates/dashboard_template";
import { Helmet } from "react-helmet";
import queryString from "query-string";
class EditProjectDashboard extends Component {
  constructor() {
    super();
    this.state = {
      pending: false,
      successForm: false,
      previewProject: false,
      skeletonValue: false,
      projectID:
        queryString.parse(window.location.search) &&
        queryString.parse(window.location.search).project,
      projectThumbnail: {},
      projectUser: {},
      projectName: "",
      projectDetails: "",
      projectCenter: null,
      file: {},
      imagePreview: "",
      beforeGallery: [],
      AfterGallery: [],
      invoicesGallery: [],
      project: {},
      projectAddress: "",
      showAddress: false,
      fileUploadproject: {},
      fileUploadProgress: 0,
      //
      tempProjectCost: 0,
      thumbnailUrl: "",
      tempBeforeforsave: [],
      tempAfterforsave: [],
      tempInvoiceforsave: [],
      projectType: "",
      thumbnailUploadUrl: "",
      uploadingAllItems: false,
      changingAddress: false,
      projectstartdateDart: "",
      projectstartdate: "",
      projectsfinishdate: "",
      projectStatus: "",
      formValidate: false,
      projectAddresses: [],
      currentProjectAddress: {},
      imageErrors: [],
      address: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.state && this.state.projectStatus) {
    }
    if (prevProps.user !== this.props.user) {
      this.setState({
        projectUser: {
          usersub: this.props.user.sub,
          email: this.props.user.email,
          username: this.props.user.username,
        },
      });
    }

    // Check if the relevant data has changed
    if (prevProps.projectItem !== this.props.projectItem) {
      var project = this.props.projectItem;
      // Replace with the actual property
      // console.log("project of project", this.props);
      // Check if the new data is different from the current state
      if (project !== this.state.project) {
        var beforeGallery = [];
        project.beforeWorkGallery.map((item) => {
          beforeGallery.unshift({
            ...item,
            preview: item.itemSrc,
          });
        });
        var editItem = {
          ...project,
          tempProjectCost:
            project &&
            project.totalCostProject &&
            Number(project.totalCostProject),
          projectType: project && project.type,
          projectStatus: project && project.status,
          thumbnail: project.thumbnail,
          thumbnailUrl: project.thumbnail,
          projectAddress: project.address.location,
          projectCenter: project.address.center,
          projectDetails: project.details,
          beforeGallery: beforeGallery,
        };
        // Update the state only if it's different to avoid infinite loop
        this.setState(
          editItem,

          () => {
            // this.setState({
            //   // thumbnailUrl: this.state.project.thumbnail,
            //   // thumbnail: this.state.project.thumbnail,
            //   // projectAddress: this.state.project.address.location,
            //   // projectCenter: this.state.project.address.center,
            //   // tempProjectCost: this.state.project.totalCostProject,
            //   // tempProjectCost: this.state.project.totalCostProject,
            // });

            // Optional callback function that runs after the state is updated

            setTimeout(() => {
              console.log("project changed editing updated:", this.state);
            }, 100);
          }
        );
      }
    }
  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  setAddress = (e, r) => {
    console.log("this is fucnking address", e);
    this.setState({
      projectAddress: e,
      showAddress: false,
      projectCenter: r,
      changingAddress: true,
    });

    setTimeout(() => {
      this.setState({ changingAddress: false });
    }, 1000);
  };

  onChangefroAddresss = async (e) => {
    this.setState({ [e.target.name]: e.target.value });
    await this.props.findAllAddresses(e.target.value);
    this.setState({ showAddress: true });
  };
  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ pending: true });
    var { projectID } = this.state;
    var captionBefore = [];
    var captionAfter = [];
    var invoicesMedia = [];
    this.state.beforeGallery.map((item, index) => {
      captionBefore.unshift({
        captionId: uuidv4() + "index" + index + 1,
        content: this.state[`beforeItem-${index + 1}`],
        dayId: `dayforproject-${projectID}`,
        ...item,
      });
    });
    this.state.AfterGallery.map((item, index) => {
      captionAfter.unshift({
        captionId: uuidv4() + "index" + index + 1,
        content: this.state[`afterItem-${index + 1}`],
        ...item,
      });
    });

    this.state.invoicesGallery.map((item, index) => {
      invoicesMedia.unshift({
        invoiceId: uuidv4() + "index" + index + 1,
        content: this.state[`invoiceItem-${index + 1}`],
        dayId: `dayforproject-${projectID}`,
        ...item,
      });
    });

    this.setState({
      tempBeforeforsave: captionBefore,
      tempAfterforsave: captionAfter,
      tempInvoiceforsave: invoicesMedia,
    });

    // var totalState = { ...this.state, captionBefore, captionAfter };

    console.log("state here", this.state, "props", this.props);

    setTimeout(() => {
      this.setState({ previewProject: true, pending: false });
    }, 2000);
  };

  async uploadImagedirect(imageFile, parameterb, urlParameter) {
    this.setState({ pending: true });
    console.log("Uploading image", imageFile);
    const onUploadProgress2 = (e) => {
      const percentage = Math.round((100 * e.loaded) / e.total);
      console.log("Percent of uploading", percentage);
      this.setState({ [parameterb]: percentage });
    };
    try {
      const formData = new FormData();
      formData.append("file", imageFile);
      // Use XMLHttpRequest to handle progress
      const xhr = new XMLHttpRequest();
      xhr.open(
        "PUT",
        `https://s3.us-west-1.amazonaws.com/projects-images-remodel/${imageFile.name.replace(
          / /g,
          "%20"
        )}`,
        true
      );

      // Set the onprogress event handler
      xhr.upload.onprogress = (e) => {
        if (e.lengthComputable) {
          const percentage = Math.round((100 * e.loaded) / e.total);
          console.log("Percent of uploading", percentage);
          onUploadProgress2(e);
        }
      };

      // Set the onload event handler
      xhr.onload = () => {
        if (xhr.status === 200) {
          // The upload was successful
          console.log("Upload successful", xhr.responseURL);
          this.setState({ [urlParameter]: xhr.responseURL }, () => {
            this.setState({ pending: false });
            console.log("Uploading state finished tiem matter", this.state);
          });

          return new Promise((resolve) => {
            setTimeout(() => {
              console.log(
                "what we have as result item here in first fucntion",
                xhr.responseURL
              );
              // Assuming the upload is successful
              resolve(true);
            }, 1000);
          });
        } else {
          // The upload failed
          console.error("Upload failed. Status:", xhr.status);
          throw new Error(xhr.status);
        }
      };
      // Send the FormData as the body of the PUT request
      xhr.send(formData);
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error; // Propagate the error if something goes wrong
    }
  }

  async fetchDataUpload(imageFile, parameterb, urlParameter) {
    console.log("Uploading image", imageFile);

    return new Promise((resolve, reject) => {
      const onUploadProgress2 = (e) => {
        const percentage = Math.round((100 * e.loaded) / e.total);
        console.log("Percent of uploading", percentage);
        this.setState({ [parameterb]: percentage });
      };

      const formData = new FormData();
      formData.append("file", imageFile);

      const xhr = new XMLHttpRequest();
      xhr.open(
        "PUT",
        `https://s3.us-west-1.amazonaws.com/projects-images-remodel/${imageFile.name.replace(
          / /g,
          "%20"
        )}`,
        true
      );

      xhr.upload.onprogress = (e) => {
        if (e.lengthComputable) {
          onUploadProgress2(e);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          this.setState({ [urlParameter]: xhr.responseURL }, () => {
            console.log("Uploading state finished time matter", this.state);
            resolve(xhr.responseURL);
          });
        } else {
          console.error("Upload failed. Status:", xhr.status);
          reject(xhr.status);
        }
      };

      xhr.send(formData);
    });
  }

  uploadDirectlytoAws = async (imageFile, parameterb, urlParameter) => {
    console.log("Uploading image by aws function", imageFile);
    return new Promise((resolve, reject) => {
      const onUploadProgress = (e) => {
        const percentage = Math.round((100 * e.loaded) / e.total);

        console.log("Percent of uploading to aws", percentage);
        this.setState({ [parameterb]: percentage });
      };
      try {
        var config = {
          method: "put",
          url: `https://s3.us-west-1.amazonaws.com/projects-images-remodel/${imageFile.name}`,
          headers: {
            "Content-Type": imageFile.type,
          },
          data: imageFile,
          onUploadProgress,
        };
        axios(config)
          .then((response) => {
            console.log("we run this part 2", this.state);
            console.log(
              "filePath.split('/').pop()",
              imageFile.name.replace(/ /g, "%20")
            );
            console.log(
              "what we have as response",
              response.status,
              "json item",
              `https://s3.us-west-1.amazonaws.com/projects-images-remodel/${imageFile.name.replace(
                / /g,
                "%20"
              )}`
            );
            if (response.status == 200) {
              var data = JSON.stringify(response, null, 2);
              console.log(
                "we load this for finalized",
                JSON.parse(data).config.url
              );

              this.setState(
                { [urlParameter]: JSON.parse(data).config.url },
                () => {
                  console.log(
                    "Uploading state finished time matter",
                    this.state
                  );
                  resolve(JSON.parse(data).config.url);
                }
              );
            } else {
              console.error("we load this for");
              reject(response.status);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    });
  };

  async exampleAsyncFunctionforUpload(imageFile, parameterb, urlParameter) {
    console.log("Before await");

    try {
      const resultFile = await this.uploadDirectlytoAws(
        imageFile,
        parameterb,
        urlParameter
      );

      console.log("After await uploading file:", resultFile);
      return resultFile;
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  }

  async fetchData() {
    try {
      const resultfromApi = await fetch(
        "https://dvuvpgw9qh.execute-api.us-east-2.amazonaws.com/dev/site"
      );

      if (!resultfromApi.ok) {
        // Uncomment the next line if you want to throw an error
        // throw new Error("Failed to fetch data from the API");
        console.log("Failed to fetch data from the API");
        return null; // Return null or another appropriate value
      }

      console.log("Successfully fetched data from the API");

      // Simplify by directly returning the result of resultfromApi.json()
      return await resultfromApi.json();
    } catch (err) {
      console.error("API error", err);
      return null; // Return null or another appropriate value
    }
  }

  async exampleAsyncFunction() {
    console.log("Before await");

    // Using await to wait for the asynchronous operation to complete
    const result = await this.fetchData();
    console.log(result);

    console.log("After await");
  }

  // Define the second asynchronous function
  uploadFilePath = async (imageFile, parameterb, urlParameter) => {
    try {
      // Call the first function with data to update and wait for its completion
      var resultItem = await this.uploadImagedirect(
        imageFile,
        parameterb,
        urlParameter
      );

      setTimeout(() => {
        // if (resultItem) {
        console.log("pending result for item", resultItem);
        // return new Promise((resolve) => {
        //   resolve(true);
        // });
        // }
      }, 1000);

      // Now you can use the result from the first function in the second function
    } catch (error) {
      console.error("Error in second function:", error);
      // Handle errors from the first or second function
    }
  };

  uploadImagedirectold = async (imageFile) => {
    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     console.log("First function completed");
    //     resolve("Result from first function");
    //   }, 2000); // Simulating a 2-second delay
    // });
    const onUploadProgress = (e) => {
      const percentage = Math.round((100 * e.loaded) / e.total);
      return percentage;
    };
    try {
      var config = {
        method: "put",
        url: `https://s3.us-west-1.amazonaws.com/projects-images-remodel/${imageFile.name}`,
        headers: {
          "Content-Type": imageFile.type,
        },
        data: imageFile,
        onUploadProgress,
      };

      axios(config)
        .then(function (response) {
          // console.log("upload data", JSON.stringify(response));
          console.log("we run this part 2");

          console.log(
            "filePath.split('/').pop()",
            imageFile.name.replace(/ /g, "%20")
          );

          console.log(
            "what we have as response",
            response.status,
            "json item",
            `https://s3.us-west-1.amazonaws.com/projects-images-remodel/${imageFile.name.replace(
              / /g,
              "%20"
            )}`
          );
          if (response.status == 200) {
            var data = JSON.stringify(response, null, 2);
            console.log(
              "we load this for finalized",
              JSON.parse(data).config.url
            );
            return true;
            return JSON.parse(data).config.url;
          } else {
            console.error("we load this for");
            return;
          }
        })
        .catch(function (error) {
          console.log(error);
          return;
        });
    } catch (error) {
      console.log(error);
    }
  };
  onFileSelected = (e) => {
    console.log("file name", e.target.files[0]);
    this.setState({ [e.target.name]: e.target.files[0] });
    this.setState({
      projectThumbnail: e.target.files[0],
      selectedFile: e.target.files[0],
      imagePreview: URL.createObjectURL(e.target.files[0]),
    });
    // const reader = new FileReader();
    // reader.onloadend = () => {
    // };
    // reader.readAsDataURL(e.target.files[0]);
  };
  onFileSelectedBeforeGallery = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const newImages = Array.from(files).map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      }));
      this.setState((prevState) => ({
        beforeGallery: [...prevState.beforeGallery, ...newImages],
      }));
      setTimeout(() => {
        console.log("before gallery of state", this.state.beforeGallery);
      }, 2000);
    }
  };
  onFileSelectedAfterGallery = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const newImages = Array.from(files).map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      }));

      this.setState((prevState) => ({
        AfterGallery: [...prevState.AfterGallery, ...newImages],
      }));

      setTimeout(() => {
        console.log("AfterGallery gallery of state", this.state.AfterGallery);
      }, 2000);
    }
  };

  onFileSelectedInvoiceGallery = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const newImages = Array.from(files).map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      }));

      this.setState((prevState) => ({
        invoicesGallery: [...prevState.invoicesGallery, ...newImages],
      }));

      setTimeout(() => {
        console.log(
          "invoicesGallery gallery of state",
          this.state.invoicesGallery
        );
      }, 2000);
    }
  };

  selectedFileUpload = (e) => {
    console.log("file selected", e);

    this.setState({ [e.target.name]: e.target.files[0] });

    console.log("file yoho", this.state);

    // this.setState({ [e.target.value]: e.target.files[0] });

    // var axios = require("axios");
    // var data = this.state.selectedFile;

    // var config = {
    //   method: "put",
    //   url: "https://s3.us-west-1.amazonaws.com/projects-images-remodel/manzarkia-logotest.png",
    //   headers: {
    //     "Content-Type": "image/png",
    //   },
    //   data: data,
    // };

    // axios(config)
    //   .then(function (response) {
    //     console.log(JSON.stringify(response.data));
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  };
  showBeforeGallery() {
    return this.state.beforeGallery.map((item, index) => {
      return (
        <div
          className="shrink-0 w-full bg-green-500 snap-center"
          key={index + 1}
        >
          <div className="mx-auto grid max-w-7xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:px-6 sm:py-0 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
            <div className="items-start">
              <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                <div className="border-t border-gray-200">
                  <dt className="font-medium text-gray-900">
                    photo before preview
                  </dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    <img
                      src={item.preview}
                      className="w-full sm:h-48 sm:w-48 aspect-square"
                    />
                  </dd>
                </div>
              </dl>
            </div>
            <div className="grid grid-cols-1 grid-rows-1 gap-4 sm:gap-6 lg:gap-8">
              <div className="w-full items-start">
                <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                  <div className="border-t border-gray-200">
                    <dt className="font-medium text-gray-900">caption</dt>
                    <dd className="mt-2 text-sm text-gray-500">
                      <textarea
                        className="w-full bg-white h-48 rounded-lg p-4"
                        type="text"
                        name={`beforeItem-${index + 1}`}
                        onChange={this.onChange}
                        value={this.state[`beforeItem-${index + 1}`]}
                      />
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  showAfterGallery() {
    return this.state.AfterGallery.map((item, index) => {
      return (
        <div
          className="shrink-0 w-full bg-green-500 snap-center"
          key={index + 1}
        >
          <div className="mx-auto grid max-w-7xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:px-6 sm:py-0 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
            <div className="items-start">
              <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                <div className="border-t border-gray-200">
                  <dt className="font-medium text-gray-900">
                    photo after preview
                  </dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    <img
                      src={item.preview}
                      className="w-full sm:h-48 sm:w-48 aspect-square"
                    />
                  </dd>
                </div>
              </dl>
            </div>
            <div className="grid grid-cols-1 grid-rows-1 gap-4 sm:gap-6 lg:gap-8">
              <div className="w-full items-start">
                <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                  <div className="border-t border-gray-200">
                    <dt className="font-medium text-gray-900">caption</dt>
                    <dd className="mt-2 text-sm text-gray-500">
                      <textarea
                        className="w-full bg-white h-48 rounded-lg p-4"
                        type="text"
                        name={`afterItem-${index + 1}`}
                        onChange={this.onChange}
                        value={this.state[`afterItem-${index + 1}`]}
                      />
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }
  showAfterGalleryold() {
    return this.state.AfterGallery.map((item, index) => {
      var afterItem = `afterItem-${index + 1}`;
      return (
        <div
          className="shrink-0 w-full bg-green-500 snap-center"
          key={index + 1}
        >
          <div className="mx-auto grid max-w-7xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:px-6 sm:py-0 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
            <div>
              <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                <div className="border-t border-gray-200 pt-4">
                  <dt className="font-medium text-gray-900">
                    photo after preview
                  </dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    <img src={item.preview} className="h-48 w-48" />
                  </dd>
                </div>
                <div className="border-t border-gray-200 pt-4">
                  <dt className="font-medium text-gray-900">preview caption</dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    {this.state[`afterItem-${index + 1}`]}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="grid grid-cols-1 grid-rows-1 gap-4 sm:gap-6 lg:gap-8">
              <div className="w-full items-start">
                <div className="border-t border-gray-200 pt-0 mb-4">
                  <dt className="font-medium text-gray-900 italic">caption</dt>
                </div>
                <textarea
                  className="w-full bg-white h-48 rounded-lg p-4"
                  type="text"
                  name={`afterItem-${index + 1}`}
                  onChange={this.onChange}
                  value={this.state[`afterItem-${index + 1}`]}
                />
              </div>
            </div>
          </div>
        </div>
      );
    });
  }
  showInvoicesGallery() {
    return this.state.invoicesGallery.map((item, index) => {
      return (
        <div
          className="shrink-0 w-full bg-green-500 snap-center"
          key={index + 1}
        >
          <div className="mx-auto grid max-w-7xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:px-6 sm:py-0 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
            <div className="items-start">
              <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                <div className="border-t border-gray-200">
                  <dt className="font-medium text-gray-900">
                    photo invoice item preview
                  </dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    <img
                      src={item.preview}
                      className="w-full sm:h-48 sm:w-48 aspect-square"
                    />
                  </dd>
                </div>
              </dl>
            </div>
            <div className="grid grid-cols-1 grid-rows-1 gap-4 sm:gap-6 lg:gap-8">
              <div className="w-full items-start">
                <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                  <div className="border-t border-gray-200">
                    <dt className="font-medium text-gray-900">caption</dt>
                    <dd className="mt-2 text-sm text-gray-500">
                      <textarea
                        className="w-full bg-white h-48 rounded-lg p-4"
                        type="text"
                        name={`invoiceItem-${index + 1}`}
                        onChange={this.onChange}
                        value={this.state[`invoiceItem-${index + 1}`]}
                      />
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }
  showInvoicesGalleryold() {
    return this.state.invoicesGallery.map((item, index) => {
      const invoiceGalleryid = uuidv4();
      return (
        <div
          className="shrink-0 w-full bg-green-500 snap-center"
          key={index + 1}
        >
          <div className="mx-auto grid max-w-7xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:px-6 sm:py-0 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
            <div>
              <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                <div className="border-t border-gray-200 pt-4">
                  <dt className="font-medium text-gray-900">
                    photo invoice preview
                  </dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    <img src={item.preview} className="h-48 w-48" />
                  </dd>
                </div>
                <div className="border-t border-gray-200 pt-4">
                  <dt className="font-medium text-gray-900">preview invoice</dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    {this.state[`invoiceItem-${index + 1}`]}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="grid grid-cols-1 grid-rows-1 gap-4 sm:gap-6 lg:gap-8">
              <div className="w-full items-start">
                <div className="border-t border-gray-200 pt-0 mb-4">
                  <dt className="font-medium text-gray-900 italic">caption</dt>
                </div>
                <textarea
                  className="w-full bg-white h-48 rounded-lg p-4"
                  type="text"
                  name={`invoiceItem-${index + 1}`}
                  onChange={this.onChange}
                  value={this.state[`invoiceItem-${index + 1}`]}
                />
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  async uploadImagesOneByOne() {
    var { tempBeforeforsave, tempAfterforsave, tempInvoiceforsave } =
      this.state;
    var tempBefore = [];
    var tempAfter = [];
    var tempInvoice = [];
    try {
      var itemforUploadThumbnailProject = await this.uploadFilePath(
        this.state.fileUploadproject,
        "fileUploadProgress",
        "thumbnailUploadUrl"
      );

      if (itemforUploadThumbnailProject) {
        // Your code here
        console.log(
          "Upload successful!yoho project added",
          itemforUploadThumbnailProject
        );
      } else {
        console.log("not yet uploaded");
      }
    } catch (error) {
      console.error("Error during upload:", error);
    }

    // tempBeforeforsave.map((item, index) => {
    //   this.uploadFilePath(
    //     item.file,
    //     `fileUploadProgress-before-${index}`,
    //     `fileUploadUrl-before-${index}`
    //   );

    //   setTimeout(() => {
    //     tempBefore.unshift({
    //       ...item,
    //       itemSrc: this.state[`fileUploadUrl-before-${index}`],
    //     });
    //     this.setState({ pending: true });
    //   }, 1200);
    // });
    // tempAfterforsave.map((item, index) => {
    //   this.uploadFilePath(
    //     item.file,
    //     `fileUploadProgress-after-${index}`,
    //     `fileUploadUrl-after-${index}`
    //   );

    //   setTimeout(() => {
    //     tempAfter.unshift({
    //       ...item,
    //       itemSrc: this.state[`fileUploadUrl-after-${index}`],
    //     });
    //     this.setState({ pending: true });
    //   }, 1200);
    // });
    // tempInvoiceforsave.map((item, index) => {
    //   this.uploadFilePath(
    //     item.file,
    //     `fileUploadProgress-invoice-${index}`,
    //     `fileUploadUrl-invoice-${index}`
    //   );

    //   setTimeout(() => {
    //     tempInvoice.unshift({
    //       ...item,
    //       itemSrc: this.state[`fileUploadUrl-invoice-${index}`],
    //     });
    //     this.setState({ pending: true });
    //   }, 1200);
    // });
    // setTimeout(() => {
    //   this.setState({ tempBeforeforsave: tempBefore });
    //   this.setState({ tempAfterforsave: tempAfter });
    //   this.setState({ tempInvoiceforsave: tempInvoice });
    // }, 3000);
  }

  async addProject(projectItemData) {
    console.log(
      "ready to get thumbnail of project",
      projectItemData,
      "all statee with fields",
      this.state
    );
    try {
      const response = await fetch(
        "https://a5hjwpyn54.execute-api.us-east-2.amazonaws.com/demo/staffs/43c308c0-fb02-4266-8a27-3c78de311c7b/addproject",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            accept:
              "text/html, application/xhtml+xml, application/xml;q=0.9, */*;q=0.8",
          },
          body: JSON.stringify(projectItemData),
        }
      );
      const resultAdded = await response.json();
      console.log("show me created form result inam okayeh", resultAdded);
    } catch (error) {
      console.error("Error adding project:", error);
    }
  }

  async addProjectworkold(projectItemData) {
    try {
      const response = await axios.post(
        "https://a5hjwpyn54.execute-api.us-east-2.amazonaws.com/demo/staffs/43c308c0-fb02-4266-8a27-3c78de311c7b/addproject",
        projectItemData,
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            accept:
              "text/html, application/xhtml+xml, application/xml;q=0.9, */*;q=0.8",
          },
        }
      );

      console.log("show me created form result perfeclty yoho", response.data);
    } catch (error) {
      console.error("Error adding project:", error);
    }
  }

  async addProjectold(projectItemData) {
    var resultAdded = await axios.post(
      "https://a5hjwpyn54.execute-api.us-east-2.amazonaws.com/demo/staffs/43c308c0-fb02-4266-8a27-3c78de311c7b/addproject",
      {
        // method: "POST",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          accept:
            "text/html, application/xhtml+xml, application/xml;q=0.9, */*;q=0.8",
        },
        body: projectItemData,
      }
    );

    console.log("show me created form result", resultAdded);
  }

  createProjectSubmit = async (e) => {
    var resultsArray = [];
    e.preventDefault();
    this.setState({ pending: true, uploadingAllItems: true });
    // this.uploadImagesOneByOne();
    var {
      fileUploadproject,
      projectAddress,
      projectDetails,
      tempProjectCost,
      projectID,
      thumbnailUrl,
      tempBeforeforsave,
      tempAfterforsave,
      tempInvoiceforsave,
    } = this.state;

    var itemcheckProject = Promise.all(
      await this.exampleAsyncFunctionforUpload(
        fileUploadproject,
        "fileUploadProgress",
        "thumbnailUploadUrl"
      )
    );

    console.log("it must laod this after uploading project", itemcheckProject);

    console.log("item of before gallery", tempBeforeforsave);
    var uploadedItemforBefore = [];
    var uploadedItemforAfter = [];
    var uploadedItemforInvoice = [];
    var beforeUploads = Promise.all(
      tempBeforeforsave.map(async (item, index) => {
        console.log("must be change by before numbers");
        var itemcheck = await this.exampleAsyncFunctionforUpload(
          item.file,
          `fileUploadProgress-before-${index}`,
          `itemSrc-${index}`
        );

        console.log("item not supposed to be undefiend", itemcheck);
        uploadedItemforBefore.unshift({
          ...item,
          type: "before-item",
          itemSrc: itemcheck,
        });

        var uploadResultBefore =
          uploadedItemforBefore.length == tempBeforeforsave.length;

        if (uploadResultBefore) {
          console.log("finally we got before", uploadResultBefore);

          this.setState({
            tempBeforeforsave: uploadedItemforBefore,
          });

          resultsArray.unshift("true");
        }
      })
    );

    var afterUploads = Promise.all(
      tempAfterforsave.map(async (item, index) => {
        console.log("must be change by after numbers");
        var itemcheck = await this.exampleAsyncFunctionforUpload(
          item.file,
          `fileUploadProgress-after-${index}`,
          `itemSrc-${index}`
        );

        console.log("item not supposed to be undefiend after", itemcheck);
        uploadedItemforAfter.unshift({
          ...item,
          type: "after-item",
          itemSrc: itemcheck,
        });

        var uploadResultAfter =
          uploadedItemforAfter.length == tempAfterforsave.length;

        if (uploadResultAfter) {
          console.log("finally we got after", uploadResultAfter);

          this.setState({
            tempAfterforsave: uploadedItemforAfter,
          });
        }
      })
    );

    var invoiceUploads = Promise.all(
      tempInvoiceforsave.map(async (item, index) => {
        console.log("must be change by invoice numbers");
        var itemcheck = await this.exampleAsyncFunctionforUpload(
          item.file,
          `fileUploadProgress-invoice-${index}`,
          `itemSrc-${index}`
        );

        console.log("item not supposed to be undefiend invoice", itemcheck);
        uploadedItemforInvoice.unshift({
          ...item,
          type: "invoice-item",
          itemSrc: itemcheck,
        });

        var uploadResultInvoice =
          uploadedItemforInvoice.length == tempInvoiceforsave.length;

        if (uploadResultInvoice) {
          console.log("finally we got invoice", uploadResultInvoice);

          this.setState({
            tempInvoiceforsave: uploadedItemforInvoice,
          });
        }
      })
    );

    Promise.all([itemcheckProject, beforeUploads, afterUploads, invoiceUploads])
      .then(() => {
        console.log(
          "All uploads complete",
          uploadedItemforInvoice.length === tempInvoiceforsave.length
        );

        // Check if both sets of uploads are complete
        if (
          uploadedItemforBefore.length === tempBeforeforsave.length &&
          uploadedItemforAfter.length === tempAfterforsave.length &&
          uploadedItemforInvoice.length === tempInvoiceforsave.length
        ) {
          console.log("finally we got before and after and invoice");

          this.setState({
            tempBeforeforsave: uploadedItemforBefore,
            tempAfterforsave: uploadedItemforAfter,
            tempInvoiceforsave: uploadedItemforInvoice,
          });

          // Run your third function here
          console.log("finally we got it");

          this.resultUploadFinished();
        }
      })
      .catch((error) => {
        console.error("Error during uploads:", error);
      });

    // console.log("state of project", this.state);

    // var projectItem = {
    //   empID: "sub id",
    //   projectstartdateDart: "",
    //   address: {
    //     location: projectAddress, //address,
    //     center: projectAddress, // center of address
    //   },

    //   projectstartdate: "",
    //   userName: "username",
    //   userId: "user id",
    //   listWorkers: [],
    //   success: "",
    //   publish: false,
    //   name: this.state.projectName,
    //   days: [],
    //   projectsfinishdate: "",

    //   details: projectDetails ?? "",
    //   totalCostProject: tempProjectCost,
    //   projectID: projectID,
    //   status: "uncomplete",
    //   thumbnail: thumbnailUrl,
    //   beforeWorkGallery: tempBeforeforsave,
    //   afterWorkGallery: tempAfterforsave,
    //   materialsneeded: tempInvoiceforsave,
    // };

    // console.log(
    //   "ready to create project to api",
    //   this.state,
    //   "project item",
    //   projectItem
    // );
  };

  resultUploadFinished() {
    var { tempBeforeforsave, tempAfterforsave, tempInvoiceforsave } =
      this.state;
    const totalUploads = [
      ...tempBeforeforsave,
      ...tempAfterforsave,
      ...tempInvoiceforsave,
    ];
    setTimeout(() => {
      console.log("final function called");

      this.setState({
        uploadingAllItems: false,
        pending: false,
        totalUploads: totalUploads,
        successForm: true,
        projectstartdateDart: this.state.projectstartdate,
      });

      console.log("this total state , uplaoding done perfectly", this.state);

      this.createProjectForForm();
    }, 1000);
  }
  // resultUploadFinishedAfterItems() {
  //   setTimeout(() => {
  //     console.log("uplaod third function after");

  //     console.log(
  //       "must be load after uploading for after items",
  //       this.state.tempAfterforsave
  //     );
  //   }, 1000);
  // }

  validateData = () => {
    var {
      projectAddress,
      projectCenter,
      projectDetails,
      projectID,
      projectName,
      projectType,
      projectUser,
      tempBeforeforsave,
      tempAfterforsave,
      tempInvoiceforsave,
      tempProjectCost,
      thumbnailUploadUrl,
      projectstartdateDart,
      projectstartdate,
      projectsfinishdate,
      projectStatus,
      fileUploadproject,
    } = this.state;
    console.log("check for validating");
    if (
      projectstartdate &&
      projectstartdateDart &&
      projectDetails &&
      projectName &&
      projectType &&
      projectCenter &&
      projectAddress &&
      fileUploadproject &&
      fileUploadproject.length > 0
    ) {
      this.setState({ formValidate: true });
    } else {
      this.setState({ formValidate: false });
    }
  };

  render() {
    const semiClass = "font-semibold text-dark capitalize text-sm";
    var { auth, site, user } = this.props;
    var {
      successForm,
      previewProject,
      skeletonValue,
      projectID,
      projectThumbnail,
      projectUser,
      projectName,
      projectDetails,
      projectCenter,
      file,
      imagePreview,
      beforeGallery,
      AfterGallery,
      invoicesGallery,
      project,
      projectAddress,
      showAddress,
      fileUploadproject,
      fileUploadProgress,
      //
      tempProjectCost,
      projectStatus,
      tempBeforeforsave,
      tempAfterforsave,
      tempInvoiceforsave,
      projectType,
      // added data
      projectstartdateDart,
      projectstartdate,
      listWorkers,
      success,
      publish,
      projectsfinishdate,
      thumbnail,
      pending,
      thumbnailUploadUrl,
    } = this.state;
    var checkforvalidation = null;

    if (
      projectDetails &&
      projectName &&
      projectType &&
      projectType !== "select type" &&
      projectStatus &&
      projectStatus !== "select status" &&
      projectCenter &&
      projectAddress &&
      fileUploadproject &&
      fileUploadproject.name
    ) {
      checkforvalidation = true;
    } else {
      checkforvalidation = false;
    }

    if (this.state.successForm) {
      return this.sweetAlert();
    }
    return (
      <DashboardTemplate
        termName="New Project"
        termActive={false}
        termLink={`/dashboard`}
        items={[
          {
            active: true,
            name: "new project",
            itemLink: `/dashboard/settings/createproject`,
          },
          {
            name: "users",
            itemLink: `/dashboard/settings/users`,
          },
          {
            name: "projects",
            itemLink: `/dashboard/settings/projects`,
          },
          // {
          //   name: "themes settings",
          //   itemLink: `/dashboard/settings/themes`,
          // },
        ]}
        contentData={
          !pending ? (
            !previewProject ? (
              <div className="bg-gray-200 w-full lg:w-[calc(100vw-15rem)]">
                <form
                  className="pt-6 px-6 content-between"
                  onSubmit={this.onSubmit}
                  ref={(el) => (this.form = el)}
                >
                  <div className="space-y-12">
                    <div className="border-b border-gray-900/10">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        project id: {projectID}
                      </h2>
                      <p className="mt-1 text-sm leading-6 text-gray-600">
                        This information will be save as yout project
                        information
                      </p>

                      <div className="mt-4 flex text-sm leading-6 text-gray-600">
                        <label
                          htmlFor="fileUploadproject"
                          className="relative cursor-pointer rounded-md font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          {imagePreview ? (
                            <img
                              src={imagePreview}
                              className="h-48 w-48 rounded-xl"
                            />
                          ) : (
                            <img
                              src="https://placehold.co/200?text=Project+Image&font=roboto"
                              alt=""
                              className="h-48 w-48 rounded-xl"
                            />
                          )}

                          <input
                            onChange={this.onFileSelected}
                            id="fileUploadproject"
                            name="fileUploadproject"
                            type="file"
                            className="sr-only"
                            multiple={false}
                          />
                        </label>
                      </div>

                      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="flex row">
                          <div className="sm:col-span-4">
                            <label
                              htmlFor="start date"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              project date of start
                            </label>
                            <div className="mt-2">
                              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                                  start:/
                                </span>
                                <input
                                  type="date"
                                  onChange={this.onChange}
                                  name="projectstartdate"
                                  id="projectstartdate"
                                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                  placeholder="project date"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="sm:col-span-4">
                            <label
                              htmlFor="finish date"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              project date of finish
                            </label>
                            <div className="mt-2">
                              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                                  finish date:/
                                </span>
                                <input
                                  type="date"
                                  onChange={this.onChange}
                                  name="projectsfinishdate"
                                  id="projectsfinishdate"
                                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                  placeholder="project finish date"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex row">
                          <div className="sm:col-span-4">
                            <label
                              htmlFor="before cost"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              project cost
                            </label>
                            <div className="mt-2">
                              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:w-[120px]">
                                <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                                  $
                                </span>
                                <input
                                  maxLength={7}
                                  type="text"
                                  onChange={this.onChange}
                                  name="tempProjectCost"
                                  id="tempProjectCost"
                                  className="block border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                  placeholder="99.99"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="sm:col-span-4">
                            <label
                              htmlFor="before name"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              project name
                            </label>
                            <div className="mt-2">
                              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                                  projects/
                                </span>
                                <input
                                  type="text"
                                  onChange={this.onChange}
                                  name="projectName"
                                  id="projectName"
                                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                  placeholder="project name"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-full">
                          <label
                            htmlFor="about"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Description
                          </label>
                          <div className="mt-2">
                            <textarea
                              onChange={this.onChange}
                              id="projectDetails"
                              name="projectDetails"
                              rows="3"
                              className="sm:max-w-md block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-4"
                            ></textarea>
                          </div>
                          <p className="mt-3 text-sm leading-6 text-gray-600">
                            Write a few sentences project description.
                          </p>
                        </div>
                      </div>
                      <div className="border-b border-gray-900/10 pb-12">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                          Project Information
                        </h2>

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="project_address"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Street address here
                            </label>
                            <div className="mt-2">
                              {!this.state.changingAddress ? (
                                <>
                                  <input
                                    name="projectAddress"
                                    value={this.state.projectAddress}
                                    onChange={this.onChangefroAddresss}
                                    type="text"
                                    className="h-[60px] p-4 items-center block w-full md:max-w-xl rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </>
                              ) : (
                                <Skeleton
                                  count={1}
                                  className="h-[60px] p-4 items-center block w-full md:max-w-xl rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              )}
                            </div>
                            {showAddress &&
                              project &&
                              project.addresses &&
                              project.addresses.length > 0 && (
                                <div className="w-full md:max-w-xl bg-white">
                                  <label
                                    htmlFor="project_address"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                  >
                                    Recommended address
                                  </label>
                                  {project.addresses.map((projectItem) => (
                                    <div
                                      className="py-2 hover:bg-red-500"
                                      onClick={() =>
                                        this.setAddress(
                                          projectItem.place_name,
                                          projectItem.center
                                        )
                                      }
                                    >
                                      {projectItem.place_name}
                                    </div>
                                  ))}
                                </div>
                              )}
                          </div>
                          <div className="sm:col-span-3 hidden">
                            <label
                              htmlFor="project_address"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Recommended address
                            </label>
                            <div className="mt-2">
                              <select
                                type="text"
                                name="project_address"
                                id="project_address"
                                className="h-[60px] px-20 items-center block w-full md:max-w-xl rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              >
                                {project.addresses &&
                                  [...project.addresses].map(
                                    (projectItem, index) => (
                                      <option
                                        className="w-full flex p-20"
                                        key={index}
                                        value={projectItem.place_name}
                                        onChange={() =>
                                          this.setAddress(
                                            projectItem.place_name
                                          )
                                        }
                                      >
                                        {projectItem.place_name}
                                      </option>
                                    )
                                  )}
                              </select>
                            </div>
                          </div>

                          <div className="sm:col-span-2">
                            <label
                              htmlFor="projectType"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              project / type
                            </label>
                            <div className="mt-2">
                              <select
                                onChange={(e) => {
                                  this.setState({
                                    projectType: e.target.value,
                                  });

                                  setTimeout(() => {
                                    console.log(this.state.projectType);
                                  }, 2000);
                                }}
                                type="text"
                                name="projectType"
                                // id="projectType"
                                className="h-[60px] w-[200px] bg-white border-2 border-gray-300 py-1.5 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 md:max-w-[12rem]"
                                // className="text-center h-[60px] block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 md:max-w-[12rem]"
                              >
                                {site &&
                                  site.types &&
                                  [{ type: "select type" }, ...site.types].map(
                                    (typeItem, index) => (
                                      <option
                                        key={typeItem.type}
                                        value={typeItem.type}
                                      >
                                        {typeItem.type}
                                      </option>
                                    )
                                  )}
                              </select>
                            </div>
                          </div>

                          <div className="sm:col-span-2">
                            <label
                              htmlFor="projectStatus"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              project / status
                            </label>
                            <div className="mt-2">
                              <select
                                onChange={(e) => {
                                  this.setState({
                                    projectStatus: e.target.value,
                                  });

                                  setTimeout(() => {
                                    console.log(this.state.projectStatus);
                                  }, 2000);
                                }}
                                type="text"
                                name="projectStatus"
                                className="h-[60px] w-[200px] bg-white border-2 border-gray-300 py-1.5 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 md:max-w-[12rem]"
                                // className="text-center h-[60px] block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 md:max-w-[12rem]"
                              >
                                {site &&
                                  [
                                    { type: "select status" },
                                    ...[
                                      { type: "uncomplete" },
                                      { type: "completed" },
                                    ],
                                    ,
                                  ].map((statusItem, index) => (
                                    <option
                                      key={statusItem.type}
                                      value={statusItem.type}
                                    >
                                      {statusItem.type}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="border-t border-gray-200 mx-auto max-w-7xl">
                        <dt className="font-medium text-gray-900">
                          add new photo for before gallery
                        </dt>
                        <dd className="my-4 text-sm text-gray-500">
                          all of our caption with their photo section will be
                          shown on dialog box, youcan swip them left and right
                        </dd>

                        <label
                          htmlFor="file-uploadBeforeGallery"
                          // className="items-center flex bg-[#dddddd]  w-full relative cursor-pointer rounded-md font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span className="bg-red-600 text-white font-semibold rounded-lg cursor-pointer p-2">
                            add a before photo
                          </span>
                        </label>
                        <input
                          onChange={this.onFileSelectedBeforeGallery}
                          id="file-uploadBeforeGallery"
                          name="file-uploadBeforeGallery"
                          type="file"
                          multiple
                          className="sr-only"
                        />
                      </div>

                      <div className="w-12/12">
                        <div
                          className={`flex w-full overflow-x-scroll min-h-20 max-w-7xl ${
                            this.state.beforeGallery.length > 0 &&
                            "snap-mandatory snap-x scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100"
                          }`}
                        >
                          {this.state.beforeGallery.length > 0 &&
                            this.showBeforeGallery()}
                        </div>
                      </div>

                      <div className="border-t border-gray-200 mx-auto max-w-7xl">
                        <dt className="font-medium text-gray-900">
                          add new photo for after gallery
                        </dt>
                        <dd className="my-4 text-sm text-gray-500">
                          all of our caption with their photo section will be
                          shown on dialog box, youcan swip them left and right
                        </dd>

                        <label
                          htmlFor="file-uploadAfterGallery"
                          // className="items-center flex bg-[#dddddd]  w-full relative cursor-pointer rounded-md font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span className="bg-red-600 text-white font-semibold rounded-lg cursor-pointer p-2">
                            add a after photo
                          </span>
                        </label>
                        <input
                          onChange={this.onFileSelectedAfterGallery}
                          id="file-uploadAfterGallery"
                          name="file-uploadAfterGallery"
                          type="file"
                          multiple
                          className="sr-only"
                        />
                      </div>

                      <div className="w-12/12">
                        <div
                          className={`flex w-full overflow-x-scroll min-h-20 max-w-7xl ${
                            this.state.AfterGallery.length > 0 &&
                            "snap-mandatory snap-x scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100"
                          }`}
                        >
                          {this.state.AfterGallery.length > 0 &&
                            this.showAfterGallery()}
                        </div>
                      </div>

                      <div className="border-gray-200 mx-auto max-w-7xl">
                        <dt className="font-medium text-gray-900">
                          add new photo for invoice gallery
                        </dt>
                        <dd className="my-4 text-sm text-gray-500">
                          all of our caption with their photo section will be
                          shown on dialog box, youcan swip them left and right
                        </dd>

                        <label
                          htmlFor="file-uploadInvoiceGallery"
                          // className="items-center flex bg-[#dddddd]  w-full relative cursor-pointer rounded-md font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span className="bg-red-600 text-white font-semibold rounded-lg cursor-pointer p-2">
                            add a invoice photo
                          </span>
                        </label>
                        <input
                          onChange={this.onFileSelectedInvoiceGallery}
                          id="file-uploadInvoiceGallery"
                          name="file-uploadInvoiceGallery"
                          type="file"
                          multiple
                          className="sr-only"
                        />
                      </div>

                      <div className="w-12/12">
                        <div
                          className={`flex w-full overflow-x-scroll min-h-20 max-w-7xl ${
                            this.state.invoicesGallery.length > 0 &&
                            "snap-mandatory snap-x scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100"
                          }`}
                        >
                          {this.state.invoicesGallery.length > 0 &&
                            this.showInvoicesGallery()}
                        </div>
                      </div>
                    </div>
                    {/* new version */}
                  </div>
                  <div className="flex items-center justify-end gap-x-6 bg-green-400 sticky bottom-0">
                    <button
                      type="button"
                      className="text-sm font-semibold leading-6 text-gray-900"
                    >
                      Cancel
                    </button>

                    <span
                      className={`${
                        checkforvalidation ? `bg-red-600` : `bg-black`
                      } p-2 rounded-sm`}
                    >
                      <button
                        disabled={!checkforvalidation ? "disabled" : ""}
                        // disabled={formValidate == true ? true : false}
                        className=" text-white font-semibold"
                        type="submit"
                      >
                        create
                        {JSON.stringify(checkforvalidation)}
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            ) : skeletonValue ? (
              <SkeletonFrame />
            ) : project && !successForm && projectID ? (
              <form
                onSubmit={this.createProjectSubmit}
                className="bg-gray-200 w-full lg:w-[calc(100vw-15rem)]"
              >
                <nav className="h-14 bg-orange-600 sticky top-0"></nav>
                <div className="bg-gray-200 w-full lg:w-[calc(100vw-15rem)]">
                  <div className="flex flex-col p-4">
                    <h2>Preview of project</h2>
                    <div className="flex flex-col space-y-1">
                      <span className={semiClass}>project name </span>
                      <span>{projectName}</span>
                      <span className={semiClass}>user id </span>
                      <span>{user.sub}</span>
                      <span className={semiClass}>project id</span>
                      <span>{projectID}</span>
                      <span className={semiClass}>start date</span>
                      <span>{projectstartdateDart}</span>
                      <span className={semiClass}>address</span>
                      {/* <span>{projectAddress && projectAddress}</span> */}
                      <span className={semiClass}>dart date</span>
                      <span>{projectstartdate}</span>
                      <span className={semiClass}>username</span>
                      <span>{user.username}</span>
                      <span className={semiClass}>user id</span>
                      <span>{user.sub}</span>
                      <span>{listWorkers}</span>
                      <span>{success}</span>
                      <span>{publish}</span>
                      <span className={semiClass}>project name</span>
                      <span>{projectName}</span>
                      <span className={semiClass}>finish date</span>
                      <span>{projectsfinishdate}</span>
                      <span className={semiClass}>project details</span>
                      <span>{projectDetails}</span>
                      <span className={semiClass}>project cost</span>
                      <span>{tempProjectCost}</span>
                      <span className={semiClass}>project id</span>
                      <span>{projectID}</span>
                      <span className={semiClass}>project status</span>
                      <span>{project.status}</span>
                      <span className={semiClass}>project thumbnail photo</span>
                      <span>{thumbnailUploadUrl}</span>
                      <span className={semiClass}>before photos</span>
                      <span className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                        {tempBeforeforsave.map((item, index) => {
                          return (
                            <img
                              key={index}
                              src={item.preview}
                              className="h-48 w-48"
                            />
                          );
                        })}
                      </span>
                      <span className={semiClass}>after photos</span>
                      <span className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                        {tempAfterforsave.map((item, index) => {
                          return (
                            <img
                              key={index}
                              src={item.preview}
                              className="h-48 w-48"
                            />
                          );
                        })}
                      </span>
                      <span className={semiClass}>materials neeeded</span>
                      <span className="w-full h-auto">
                        <span className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                          {tempInvoiceforsave.map((item, index) => {
                            return (
                              <img
                                key={index}
                                src={item.preview}
                                className="h-48 w-48"
                              />
                            );
                          })}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="sticky bottom-0 bg-red-600 flex justify-center h-20 items-center">
                  <button type="submit"> create project</button>
                </div>
              </form>
            ) : (
              <div onLoad={() => this.sweetAlert}>nice rebele</div>
            )
          ) : this.state.uploadingAllItems ? (
            <section className="flex-col w-full lg:w-[calc(100vw-15rem)] p-4">
              {!this.state.successForm ? (
                <>
                  <span className={semiClass}>Uploading files...</span>
                  <div className="w-full flex flex-col space-y-4 content-start">
                    {tempBeforeforsave.map((item, index) => {
                      return (
                        <div className="flex-col">
                          <h3>{item.file.name}</h3>
                          <progress
                            key={index}
                            className="w-[300px]"
                            value={
                              this.state[`fileUploadProgress-before-${index}`]
                            }
                            max="100"
                          />
                        </div>
                      );
                    })}
                    <hr />
                    {tempAfterforsave.map((item, index) => {
                      return (
                        <div className="flex-col">
                          <h3>{item.file.name}</h3>

                          <progress
                            key={index}
                            className="w-[300px]"
                            value={
                              this.state[`fileUploadProgress-after-${index}`]
                            }
                            max="100"
                          />
                        </div>
                      );
                    })}
                    <hr />
                    {tempInvoiceforsave.map((item, index) => {
                      return (
                        <div className="flex-col">
                          <h3>{item.file.name}</h3>

                          <progress
                            key={index}
                            className="w-[300px]"
                            value={
                              this.state[`fileUploadProgress-invoice-${index}`]
                            }
                            max="100"
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <div>pending submitted</div>
              )}
            </section>
          ) : (
            <>pending</>
          )
        }
        seo={
          <Helmet>
            <meta name="description" content="admin section of website" />
            <title>new page</title>
          </Helmet>
        }
      />
    );
  }
  renderold() {
    const semiClass = "font-semibold text-dark capitalize text-sm";
    var { auth, site, user } = this.props;
    var {
      successForm,
      previewProject,
      skeletonValue,
      projectID,
      projectThumbnail,
      projectUser,
      projectName,
      projectDetails,
      projectCenter,
      file,
      imagePreview,
      beforeGallery,
      AfterGallery,
      invoicesGallery,
      project,
      projectAddress,
      showAddress,
      fileUploadproject,
      fileUploadProgress,
      //
      tempProjectCost,
      projectStatus,
      tempBeforeforsave,
      tempAfterforsave,
      tempInvoiceforsave,
      projectType,
      // added data
      projectstartdateDart,
      projectstartdate,
      listWorkers,
      success,
      publish,
      projectsfinishdate,
      thumbnail,
      pending,
      thumbnailUploadUrl,
    } = this.state;
    var checkforvalidation = null;

    if (
      projectDetails &&
      projectName &&
      projectType &&
      projectType !== "select type" &&
      projectStatus &&
      projectStatus !== "select status" &&
      projectCenter &&
      projectAddress &&
      fileUploadproject &&
      fileUploadproject.name
    ) {
      checkforvalidation = true;
    } else {
      checkforvalidation = false;
    }

    if (this.state.successForm) {
      return this.sweetAlert();
    }
    return (
      <>
        <div id="appadmincp" className="flex justify-end">
          <AsideComponent />
          {!pending ? (
            !previewProject ? (
              <div className="bg-gray-200 w-full lg:w-[calc(100vw-15rem)]">
                <form
                  className="pt-6 px-6 content-between"
                  onSubmit={this.onSubmit}
                  ref={(el) => (this.form = el)}
                >
                  <div className="space-y-12">
                    <div className="border-b border-gray-900/10">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        project id: {projectID}
                      </h2>
                      <p className="mt-1 text-sm leading-6 text-gray-600">
                        This information will be save as yout project
                        information
                      </p>

                      <div className="mt-4 flex text-sm leading-6 text-gray-600">
                        <label
                          htmlFor="fileUploadproject"
                          className="relative cursor-pointer rounded-md font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          {imagePreview ? (
                            <img
                              src={imagePreview}
                              className="h-48 w-48 rounded-xl"
                            />
                          ) : (
                            <img
                              src="https://placehold.co/200?text=Project+Image&font=roboto"
                              alt=""
                              className="h-48 w-48 rounded-xl"
                            />
                          )}

                          <input
                            onChange={this.onFileSelected}
                            id="fileUploadproject"
                            name="fileUploadproject"
                            type="file"
                            className="sr-only"
                            multiple={false}
                          />
                        </label>
                      </div>

                      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="flex row">
                          <div className="sm:col-span-4">
                            <label
                              htmlFor="start date"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              project date of start
                            </label>
                            <div className="mt-2">
                              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                                  start:/
                                </span>
                                <input
                                  type="date"
                                  onChange={this.onChange}
                                  name="projectstartdate"
                                  id="projectstartdate"
                                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                  placeholder="project date"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="sm:col-span-4">
                            <label
                              htmlFor="finish date"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              project date of finish
                            </label>
                            <div className="mt-2">
                              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                                  finish date:/
                                </span>
                                <input
                                  type="date"
                                  onChange={this.onChange}
                                  name="projectsfinishdate"
                                  id="projectsfinishdate"
                                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                  placeholder="project finish date"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex row">
                          <div className="sm:col-span-4">
                            <label
                              htmlFor="before cost"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              project cost
                            </label>
                            <div className="mt-2">
                              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:w-[120px]">
                                <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                                  $
                                </span>
                                <input
                                  maxLength={7}
                                  type="text"
                                  onChange={this.onChange}
                                  name="tempProjectCost"
                                  id="tempProjectCost"
                                  className="block border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                  placeholder="99.99"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="sm:col-span-4">
                            <label
                              htmlFor="before name"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              project name
                            </label>
                            <div className="mt-2">
                              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                                  projects/
                                </span>
                                <input
                                  type="text"
                                  onChange={this.onChange}
                                  name="projectName"
                                  id="projectName"
                                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                  placeholder="project name"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-full">
                          <label
                            htmlFor="about"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Description
                          </label>
                          <div className="mt-2">
                            <textarea
                              onChange={this.onChange}
                              id="projectDetails"
                              name="projectDetails"
                              rows="3"
                              className="sm:max-w-md block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-4"
                            ></textarea>
                          </div>
                          <p className="mt-3 text-sm leading-6 text-gray-600">
                            Write a few sentences project description.
                          </p>
                        </div>
                      </div>
                      <div className="border-b border-gray-900/10 pb-12">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                          Project Information
                        </h2>

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="project_address"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Street address here
                            </label>
                            <div className="mt-2">
                              {!this.state.changingAddress ? (
                                <>
                                  <input
                                    name="projectAddress"
                                    value={this.state.projectAddress}
                                    onChange={this.onChangefroAddresss}
                                    type="text"
                                    className="h-[60px] p-4 items-center block w-full md:max-w-xl rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </>
                              ) : (
                                <Skeleton
                                  count={1}
                                  className="h-[60px] p-4 items-center block w-full md:max-w-xl rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              )}
                            </div>
                            {showAddress &&
                              project &&
                              project.addresses &&
                              project.addresses.length > 0 && (
                                <div className="w-full md:max-w-xl bg-white">
                                  <label
                                    htmlFor="project_address"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                  >
                                    Recommended address
                                  </label>
                                  {project.addresses.map((projectItem) => (
                                    <div
                                      className="py-2 hover:bg-red-500"
                                      onClick={() =>
                                        this.setAddress(
                                          projectItem.place_name,
                                          projectItem.center
                                        )
                                      }
                                    >
                                      {projectItem.place_name}
                                    </div>
                                  ))}
                                </div>
                              )}
                          </div>
                          <div className="sm:col-span-3 hidden">
                            <label
                              htmlFor="project_address"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Recommended address
                            </label>
                            <div className="mt-2">
                              <select
                                type="text"
                                name="project_address"
                                id="project_address"
                                className="h-[60px] px-20 items-center block w-full md:max-w-xl rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              >
                                {project.addresses &&
                                  [...project.addresses].map(
                                    (projectItem, index) => (
                                      <option
                                        className="w-full flex p-20"
                                        key={index}
                                        value={projectItem.place_name}
                                        onChange={() =>
                                          this.setAddress(
                                            projectItem.place_name
                                          )
                                        }
                                      >
                                        {projectItem.place_name}
                                      </option>
                                    )
                                  )}
                              </select>
                            </div>
                          </div>

                          <div className="sm:col-span-2">
                            <label
                              htmlFor="projectType"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              project / type
                            </label>
                            <div className="mt-2">
                              <select
                                onChange={(e) => {
                                  this.setState({
                                    projectType: e.target.value,
                                  });

                                  setTimeout(() => {
                                    console.log(this.state.projectType);
                                  }, 2000);
                                }}
                                type="text"
                                name="projectType"
                                // id="projectType"
                                className="h-[60px] w-[200px] bg-white border-2 border-gray-300 py-1.5 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 md:max-w-[12rem]"
                                // className="text-center h-[60px] block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 md:max-w-[12rem]"
                              >
                                {site &&
                                  [{ type: "select type" }, ...site.types].map(
                                    (typeItem, index) => (
                                      <option
                                        key={typeItem.type}
                                        value={typeItem.type}
                                      >
                                        {typeItem.type}
                                      </option>
                                    )
                                  )}
                              </select>
                            </div>
                          </div>

                          <div className="sm:col-span-2">
                            <label
                              htmlFor="projectStatus"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              project / status
                            </label>
                            <div className="mt-2">
                              <select
                                onChange={(e) => {
                                  this.setState({
                                    projectStatus: e.target.value,
                                  });

                                  setTimeout(() => {
                                    console.log(this.state.projectStatus);
                                  }, 2000);
                                }}
                                type="text"
                                name="projectStatus"
                                className="h-[60px] w-[200px] bg-white border-2 border-gray-300 py-1.5 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 md:max-w-[12rem]"
                                // className="text-center h-[60px] block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 md:max-w-[12rem]"
                              >
                                {site &&
                                  [
                                    { type: "select status" },
                                    ...[
                                      { type: "uncomplete" },
                                      { type: "completed" },
                                    ],
                                    ,
                                  ].map((statusItem, index) => (
                                    <option
                                      key={statusItem.type}
                                      value={statusItem.type}
                                    >
                                      {statusItem.type}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="border-t border-gray-200 mx-auto max-w-7xl">
                        <dt className="font-medium text-gray-900">
                          add new photo for before gallery
                        </dt>
                        <dd className="my-4 text-sm text-gray-500">
                          all of our caption with their photo section will be
                          shown on dialog box, youcan swip them left and right
                        </dd>

                        <label
                          htmlFor="file-uploadBeforeGallery"
                          // className="items-center flex bg-[#dddddd]  w-full relative cursor-pointer rounded-md font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span className="bg-red-600 text-white font-semibold rounded-lg cursor-pointer p-2">
                            add a before photo
                          </span>
                        </label>
                        <input
                          onChange={this.onFileSelectedBeforeGallery}
                          id="file-uploadBeforeGallery"
                          name="file-uploadBeforeGallery"
                          type="file"
                          multiple
                          className="sr-only"
                        />
                      </div>

                      <div className="w-12/12">
                        <div
                          className={`flex w-full overflow-x-scroll min-h-20 max-w-7xl ${
                            this.state.beforeGallery.length > 0 &&
                            "snap-mandatory snap-x scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100"
                          }`}
                        >
                          {this.state.beforeGallery.length > 0 &&
                            this.showBeforeGallery()}
                        </div>
                      </div>

                      <div className="border-t border-gray-200 mx-auto max-w-7xl">
                        <dt className="font-medium text-gray-900">
                          add new photo for after gallery
                        </dt>
                        <dd className="my-4 text-sm text-gray-500">
                          all of our caption with their photo section will be
                          shown on dialog box, youcan swip them left and right
                        </dd>

                        <label
                          htmlFor="file-uploadAfterGallery"
                          // className="items-center flex bg-[#dddddd]  w-full relative cursor-pointer rounded-md font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span className="bg-red-600 text-white font-semibold rounded-lg cursor-pointer p-2">
                            add a after photo
                          </span>
                        </label>
                        <input
                          onChange={this.onFileSelectedAfterGallery}
                          id="file-uploadAfterGallery"
                          name="file-uploadAfterGallery"
                          type="file"
                          multiple
                          className="sr-only"
                        />
                      </div>

                      <div className="w-12/12">
                        <div
                          className={`flex w-full overflow-x-scroll min-h-20 max-w-7xl ${
                            this.state.AfterGallery.length > 0 &&
                            "snap-mandatory snap-x scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100"
                          }`}
                        >
                          {this.state.AfterGallery.length > 0 &&
                            this.showAfterGallery()}
                        </div>
                      </div>

                      <div className="border-gray-200 mx-auto max-w-7xl">
                        <dt className="font-medium text-gray-900">
                          add new photo for invoice gallery
                        </dt>
                        <dd className="my-4 text-sm text-gray-500">
                          all of our caption with their photo section will be
                          shown on dialog box, youcan swip them left and right
                        </dd>

                        <label
                          htmlFor="file-uploadInvoiceGallery"
                          // className="items-center flex bg-[#dddddd]  w-full relative cursor-pointer rounded-md font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span className="bg-red-600 text-white font-semibold rounded-lg cursor-pointer p-2">
                            add a invoice photo
                          </span>
                        </label>
                        <input
                          onChange={this.onFileSelectedInvoiceGallery}
                          id="file-uploadInvoiceGallery"
                          name="file-uploadInvoiceGallery"
                          type="file"
                          multiple
                          className="sr-only"
                        />
                      </div>

                      <div className="w-12/12">
                        <div
                          className={`flex w-full overflow-x-scroll min-h-20 max-w-7xl ${
                            this.state.invoicesGallery.length > 0 &&
                            "snap-mandatory snap-x scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100"
                          }`}
                        >
                          {this.state.invoicesGallery.length > 0 &&
                            this.showInvoicesGallery()}
                        </div>
                      </div>
                    </div>
                    {/* new version */}
                  </div>
                  <div className="flex items-center justify-end gap-x-6 bg-green-400 sticky bottom-0">
                    <button
                      type="button"
                      className="text-sm font-semibold leading-6 text-gray-900"
                    >
                      Cancel
                    </button>

                    <span
                      className={`${
                        checkforvalidation ? `bg-red-600` : `bg-black`
                      } p-2 rounded-sm`}
                    >
                      <button
                        disabled={!checkforvalidation ? "disabled" : ""}
                        // disabled={formValidate == true ? true : false}
                        className=" text-white font-semibold"
                        type="submit"
                      >
                        create
                        {JSON.stringify(checkforvalidation)}
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            ) : skeletonValue ? (
              <SkeletonFrame />
            ) : project && !successForm && projectID ? (
              <form
                onSubmit={this.createProjectSubmit}
                className="bg-gray-200 w-full lg:w-[calc(100vw-15rem)]"
              >
                <nav className="h-14 bg-orange-600 sticky top-0"></nav>
                <div className="bg-gray-200 w-full lg:w-[calc(100vw-15rem)]">
                  <div className="flex flex-col p-4">
                    <h2>Preview of project</h2>
                    <div className="flex flex-col space-y-1">
                      <span className={semiClass}>project name </span>
                      <span>{projectName}</span>
                      <span className={semiClass}>user id </span>
                      <span>{user.sub}</span>
                      <span className={semiClass}>project id</span>
                      <span>{projectID}</span>
                      <span className={semiClass}>start date</span>
                      <span>{projectstartdateDart}</span>
                      <span className={semiClass}>address</span>
                      {/* <span>{projectAddress && projectAddress}</span> */}
                      <span className={semiClass}>dart date</span>
                      <span>{projectstartdate}</span>
                      <span className={semiClass}>username</span>
                      <span>{user.username}</span>
                      <span className={semiClass}>user id</span>
                      <span>{user.sub}</span>
                      <span>{listWorkers}</span>
                      <span>{success}</span>
                      <span>{publish}</span>
                      <span className={semiClass}>project name</span>
                      <span>{projectName}</span>
                      <span className={semiClass}>finish date</span>
                      <span>{projectsfinishdate}</span>
                      <span className={semiClass}>project details</span>
                      <span>{projectDetails}</span>
                      <span className={semiClass}>project cost</span>
                      <span>{tempProjectCost}</span>
                      <span className={semiClass}>project id</span>
                      <span>{projectID}</span>
                      <span className={semiClass}>project status</span>
                      <span>{project.status}</span>
                      <span className={semiClass}>project thumbnail photo</span>
                      <span>{thumbnailUploadUrl}</span>
                      <span className={semiClass}>before photos</span>
                      <span className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                        {tempBeforeforsave.map((item, index) => {
                          return (
                            <img
                              key={index}
                              src={item.preview}
                              className="h-48 w-48"
                            />
                          );
                        })}
                      </span>
                      <span className={semiClass}>after photos</span>
                      <span className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                        {tempAfterforsave.map((item, index) => {
                          return (
                            <img
                              key={index}
                              src={item.preview}
                              className="h-48 w-48"
                            />
                          );
                        })}
                      </span>
                      <span className={semiClass}>materials neeeded</span>
                      <span className="w-full h-auto">
                        <span className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                          {tempInvoiceforsave.map((item, index) => {
                            return (
                              <img
                                key={index}
                                src={item.preview}
                                className="h-48 w-48"
                              />
                            );
                          })}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="sticky bottom-0 bg-red-600 flex justify-center h-20 items-center">
                  <button type="submit"> create project</button>
                </div>
              </form>
            ) : (
              <div onLoad={() => this.sweetAlert}>nice rebele</div>
            )
          ) : this.state.uploadingAllItems ? (
            <section className="flex-col w-full lg:w-[calc(100vw-15rem)] p-4">
              {!this.state.successForm ? (
                <>
                  <span className={semiClass}>Uploading files...</span>
                  <div className="w-full flex flex-col space-y-4 content-start">
                    {tempBeforeforsave.map((item, index) => {
                      return (
                        <div className="flex-col">
                          <h3>{item.file.name}</h3>
                          <progress
                            key={index}
                            className="w-[300px]"
                            value={
                              this.state[`fileUploadProgress-before-${index}`]
                            }
                            max="100"
                          />
                        </div>
                      );
                    })}
                    <hr />
                    {tempAfterforsave.map((item, index) => {
                      return (
                        <div className="flex-col">
                          <h3>{item.file.name}</h3>

                          <progress
                            key={index}
                            className="w-[300px]"
                            value={
                              this.state[`fileUploadProgress-after-${index}`]
                            }
                            max="100"
                          />
                        </div>
                      );
                    })}
                    <hr />
                    {tempInvoiceforsave.map((item, index) => {
                      return (
                        <div className="flex-col">
                          <h3>{item.file.name}</h3>

                          <progress
                            key={index}
                            className="w-[300px]"
                            value={
                              this.state[`fileUploadProgress-invoice-${index}`]
                            }
                            max="100"
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <div>pending submitted</div>
              )}
            </section>
          ) : (
            <>pending</>
          )}
        </div>
      </>
    );
  }

  createProjectForForm() {
    var {
      projectAddress,
      projectCenter,
      projectDetails,
      projectID,
      projectName,
      projectType,
      projectUser,
      tempBeforeforsave,
      tempAfterforsave,
      tempInvoiceforsave,
      tempProjectCost,
      thumbnailUploadUrl,
      projectstartdateDart,
      projectstartdate,
      projectsfinishdate,
      projectStatus,
    } = this.state;

    // form of project
    var projectItem = {
      projectName: projectName,
      empID: this.props.user.sub,
      projectstartdateDart: projectstartdate,
      address: {
        location: projectAddress, //address,
        center: projectCenter, // center of address
      },
      type: projectType,
      projectstartdate: projectstartdate,
      userName: this.props.user.username,
      managerEmail: this.props.user.email,
      userId: this.props.user.sub,
      listWorkers: [],
      success: "",
      publish: false,
      name: this.state.projectName,
      days: [],
      projectsfinishdate: projectsfinishdate,
      details: projectDetails,
      totalCostProject: Number(tempProjectCost).toFixed(2),
      projectID: projectID,
      status: projectStatus ?? "uncomplete",
      thumbnail: thumbnailUploadUrl,
      beforeWorkGallery: tempBeforeforsave,
      afterWorkGallery: tempAfterforsave,
      materialsneeded: tempInvoiceforsave,
    };

    console.log("project ready to upload", projectItem);

    this.addProject(projectItem);
  }

  sweetAlert() {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Your project has been saved",
      showConfirmButton: false,
      timer: 1500,
    });

    setTimeout(() => {
      window.location.href = "/dashboard/settings/projects";
    }, 2000);
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  findAllAddresses,
  uploadImage,
  singleProject,
  // uploadingProcess,
})(EditProjectDashboard);
