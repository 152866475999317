import { combineReducers } from "redux";

import errorReducer from "./error_reducer";
import authReducer from "./auth_reducer";
import siteReducer from "./site_reducer";
import projectReducer from "./project_reducer";

export default combineReducers({
  errors: errorReducer,
  auth: authReducer,
  site: siteReducer,
  project: projectReducer,
});
