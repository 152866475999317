import React, { Component } from "react";

export default class FooterSection extends Component {
  render() {
    const { services } = this.props;
    return (
      <div className="footer wow fadeIn" data-wow-delay="0.3s">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <div className="footer-contact">
                <h2>Office Contact</h2>
                <p>
                  <i className="fa fa-map-marker-alt"></i>
                  USA
                </p>
                <p>
                  <i className="fa fa-user"></i>
                  Sohrab Ahmadi
                </p>
                <p>
                  <i className="fa fa-phone-alt"></i>
                  <a href="tel:+15103861072">(510) 386 1072</a>
                </p>
                <p>
                  <i className="fa fa-envelope"></i>info@remodelchief.com
                </p>
                <p>
                  <i className="fa fa-envelope"></i>remodelchief@gmail.com
                </p>
                <div className="footer-social">
                  <a href="/">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="/">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="/">
                    <i className="fab fa-youtube"></i>
                  </a>
                  <a href="/">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="/">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="hidden col-md-6 col-lg-3">
              <div className="footer-link">
                <h2>Services Areas</h2>
                {services &&
                  services.map((service) => (
                    <a
                      key={service.name}
                      href={`/services?service=${service.serviceId}`}
                    >
                      {service.name}
                    </a>
                  ))}
                {/* <a href="/">Building Construction</a>
                <a href="/">House Renovation</a>
                <a href="/">Architecture Design</a>
                <a href="/">Interior Design</a>
                <a href="/">Painting</a> */}
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="newsletter bg-red-600 flex-col hidden">
                <h2>Newsletter</h2>
                <p>
                  Follow us for real-time updates, behind-the-scenes glimpses,
                  and community engagement.
                </p>

                <div>nice</div>
                <div className="formb flex-col">
                  <input className="form-control" placeholder="Email here" />
                  <button className="btn">Submit</button>
                </div>
              </div>

              <div className="hidden">
                <h2>Newsletter</h2>
                <p>
                  Follow us for real-time updates, behind-the-scenes glimpses,
                  and community engagement.
                </p>

                <div className="form newsletter mt-4">
                  <input className="form-control" placeholder="Email here" />
                  <button className="btn">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container footer-menu">
          <div className="f-menu">
            <a href="/">Terms of use</a>
            <a href="/">Privacy policy</a>
            <a href="/">Cookies</a>
            <a href="/">Help</a>
            <a href="/">FQAs</a>
          </div>
        </div>
        <div className="container copyright">
          <div className="row">
            <div className="col-md-3">
              <p>
                &copy; <a href="/">remodelchief</a>
              </p>
            </div>
            <div className="col-md-6">
              <p>
                Converted to Next-React web application by
                <a href="https://springdesignapp.com"> Spring</a>
              </p>
            </div>
            <div className="col-md-3">
              <p>
                Designed By <a href="https://htmlcodex.com">HTML Codex</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
