import React, { Component } from "react";
import { ServiceSection, SingleServicePage } from "../index_components";
import NormalTemplate from "../../templates/normal_template";
import queryString from "query-string";

export default class ServicesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Set your initial state here
    };
  }

  componentDidMount() {
    // Move code with side effects here
    // For example, API calls or subscriptions
  }

  render() {
    const { services } = this.props;
    if (queryString.parse(window.location.search).service) {
      var serviceId = queryString.parse(window.location.search).service;
      var serviceItem = services.filter(
        (service) => service.serviceId === serviceId
      )[0];
    }

    if (serviceId) {
      return serviceItem ? (
        <SingleServicePage serviceId={serviceId} serviceItem={serviceItem} />
      ) : (
        <NormalTemplate
          navs={[
            {
              name: "home",
              routeName: "/",
            },
            {
              name: "about us",
              routeName: "/about",
            },
            {
              name: "services",
              routeName: "/services",
              active: true,
            },
            {
              name: "contact",
              routeName: "/contact",
            },
          ]}
          authenticated={false}
        >
          <div className="items-center flex h-40 w-full justify-center">
            not item found
          </div>
        </NormalTemplate>
      );
    } else {
      return (
        <NormalTemplate
          navs={[
            {
              name: "home",
              routeName: "/",
            },
            {
              name: "about us",
              routeName: "/about",
            },
            {
              name: "services",
              routeName: "/services",
              active: true,
            },
            {
              name: "contact",
              routeName: "/contact",
            },
          ]}
          authenticated={false}
        >
          {!serviceId && <ServiceSection services={services} />}
        </NormalTemplate>
      );
    }
  }
}
