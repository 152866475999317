import React, { Component } from "react";
import NormalTemplate from "../../../templates/normal_template";
import ServiceItem from "../../service_item";
export default class SingleServicePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Set your initial state here
    };
  }

  componentDidMount() {
    // Move code with side effects here
    // For example, API calls or subscriptions
  }

  render() {
    var { services, serviceItem } = this.props;

    return (
      <NormalTemplate
        navs={[
          {
            name: "home",
            routeName: "/",
          },
          {
            name: "about us",
            routeName: "/about",
          },
          {
            name: "services",
            routeName: "/services",
            active: true,
          },
          {
            name: "contact",
            routeName: "/contact",
          },
        ]}
        authenticated={false}
      >
        <ServiceItem services={services} serviceItem={serviceItem} />
      </NormalTemplate>
    );
  }
}
