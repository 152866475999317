import React, { Component } from "react";
import { NavSection } from "./index_components";
export default class HeaderSection extends Component {
  render() {
    const { navs, authenticated } = this.props;
    return (
      <>
        <div className="top-bar">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-12">
                <div className="py-6">
                  <a
                    href="/"
                    className="flex flex-row justify-start items-center"
                  >
                    <img
                      src="/logo512.png"
                      alt="Logo"
                      className="w-[4rem] h-[4rem]"
                    />
                    <h1 className="font-extrabold text-4xl">RemodelChief</h1>
                  </a>
                </div>
              </div>
              <div className="col-lg-8 col-md-7 d-none d-lg-block">
                <div className="row">
                  <div className="col-4">
                    <div className="top-bar-item">
                      <div className="top-bar-icon">
                        <i className="flaticon-calendar"></i>
                      </div>
                      <div className="top-bar-text">
                        <h3>Opening Hour</h3>
                        <p>Mon - Fri, 8:00 - 9:00</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="top-bar-item">
                      <div className="top-bar-icon">
                        <i className="flaticon-call"></i>
                      </div>
                      <div className="top-bar-text">
                        <h3>Contact Us</h3>
                        <p>
                          <a href="tel:+15103861072">(510) 386 1072</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="top-bar-item">
                      <div className="top-bar-icon">
                        <i className="flaticon-send-mail"></i>
                      </div>
                      <div className="top-bar-text">
                        <h3>Email Us</h3>
                        <p>info@remodelchief.com</p>
                        <p>remodelchief@gmail.com</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <NavSection navs={navs} authenticated={authenticated} />
      </>
    );
  }
}
