import React, { Component } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { AlertDialogBox } from "./ui/alert_dialog_box";
import FormBoxWidget from "./ui/form_box_widget";
import { postData } from "../actions/site_action";
const includedFeatures = [
  "Private forum access",
  "Member resources",
  "Entry to annual conference",
  "Official member t-shirt",
];

const ServiceItem = ({ serviceItem }) => {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Feel Free to contact us
          </h2>

          <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {" "}
            <a href="tel:+15103861072">(510) 386 1072</a>
          </h2>
        </div>
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">
              {serviceItem.name}
            </h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              {serviceItem.description}
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
                What’s included
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {serviceItem.whatsIncluded.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon
                    className="h-6 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="relative rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="w-full h-full absolute top-0 z-1 rounded-2xl">
                <img
                  src={serviceItem.itemSrc}
                  alt={serviceItem.description}
                  className="object-cover h-full w-full rounded-2xl"
                />
              </div>

              <div className="mx-auto max-w-xs px-8 relative">
                <p className="text-base font-semibold text-gray-600 z-50">
                  Feel Free to
                </p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">
                    contact us
                  </span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600 hidden">
                    USD
                  </span>
                </p>
                {/* <a
              href="#"
              className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Get a Free Quote
            </a> */}

                <AlertDialogBox
                  title="Get a Free Quote"
                  onSubmit={() => {
                    console.log("submit this data nice re belle");

                    // const formData = {
                    //   fullName: "Dadass form",
                    //   phoneCustomer: "81818188181799980",
                    //   quoteTopic: "topic name test",
                    //   emailCustomer: "emailtest@email.com",
                    // };

                    // this.props.postData(formData);
                  }}
                  description={<FormBoxWidget item={serviceItem} />}
                  // actionCall="submit my quote"
                  // actionCancel="cancel my quote"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                />
                <p
                  // className="mt-6 text-xs leading-5 text-gray-600"
                  className="w-full rounded-md bg-[#fdbe33] px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 hover:bg-black"
                >
                  <a href="tel:+15103861072">(510) 386 1072</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceItem;

class ServiceItem2 extends Component {
  render() {
    var { serviceItem } = this.props;
    return (
      <>
        {/* <div className="mx-auto max-w-6xl px-2 sm:px-6 lg:px-8 list-none flex gap-3 bg-red-600">
          <li>home</li>
          <li>home</li>
          <li>home</li>
        </div> */}

        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl sm:text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {serviceItem.name}
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                {serviceItem.description}
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
              <div className="p-8 sm:p-10 lg:flex-auto">
                <h3 className="text-2xl font-bold tracking-tight text-gray-900">
                  more details
                </h3>
                <p className="mt-6 text-base leading-7 text-gray-600">
                  What we have
                </p>
                <div className="mt-10 flex items-center gap-x-4">
                  <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
                    What’s included
                  </h4>
                  <div className="h-px flex-auto bg-gray-100" />
                </div>
                <ul
                  role="list"
                  className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                >
                  {serviceItem.whatsIncluded.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon
                        className="h-6 w-5 flex-none text-indigo-600"
                        aria-hidden="true"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                <div className="relative rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                  <div className="w-full h-full absolute top-0 z-1 rounded-2xl">
                    <img
                      src={serviceItem.itemSrc}
                      alt={serviceItem.description}
                      className="object-cover h-full w-full rounded-2xl"
                    />
                  </div>

                  <div className="mx-auto max-w-xs px-8 relative">
                    <p className="text-base font-semibold text-gray-600 z-50">
                      Feel Free to
                    </p>
                    <p className="mt-6 flex items-baseline justify-center gap-x-2">
                      <span className="text-5xl font-bold tracking-tight text-gray-900">
                        contact us
                      </span>
                      <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600 hidden">
                        USD
                      </span>
                    </p>
                    {/* <a
                      href="#"
                      className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Get a Free Quote
                    </a> */}

                    <AlertDialogBox
                      title="Get a Free Quote"
                      description={<FormBoxWidget />}
                      actionCall="submit my quote"
                      actionCancel="cancel my quote"
                      className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    />
                    <p className="mt-6 text-xs leading-5 text-gray-600">
                      Invoices and receipts available for easy company
                      reimbursement
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
