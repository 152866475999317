import React, { Component } from "react";
// slide photo
import c1 from "../img/carousel-1.jpg";
import c2 from "../img/carousel-2.jpg";
import c3 from "../img/carousel-3.jpg";

export default class CarouselSection extends Component {
  slidersLoop = (items) => {
    return items.slice(1, items.length - 1).map(
      (item, index) =>
        item.itemSrc.length > 0 && (
          <div className="carousel-item" key={item.name}>
            <img src={item.itemSrc} alt={item.name} />
            <div className="carousel-caption">
              <p className="animated fadeInRight">We Are Professional</p>
              <h1 className="animated fadeInLeft">{item.name}</h1>
              <a
                className="btn animated fadeInUp"
                href={`/services?service=${item.serviceId}`}
              >
                Get a Free Quote
              </a>

              <a
                className="mt-2 btn animated fadeInUp p-4"
                href="tel:+15103861072"
              >
                (510) 386 1072
              </a>
            </div>
          </div>
        )
    );
  };
  render() {
    const { sliders } = this.props;
    return (
      sliders && (
        <div id="carousel" className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators hidden">
            <li
              data-target="#carousel"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#carousel" data-slide-to="1"></li>
            <li data-target="#carousel" data-slide-to="2"></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={sliders[0].itemSrc} alt="slide-one" />
              <div className="carousel-caption">
                <p className="animated fadeInRight">We Are Professional</p>
                <h1 className="animated fadeInLeft">{sliders[0].name}</h1>
                <a
                  className="btn animated fadeInUp"
                  href={`/services?service=${sliders[0].serviceId}`}
                >
                  Get a Free Quote
                </a>
                <a
                  className="mt-2 btn animated fadeInUp p-4"
                  href="tel:+15103861072"
                >
                  (510) 386 1072
                </a>
              </div>
            </div>

            {this.slidersLoop(sliders)}
          </div>

          <a
            className="carousel-control-prev"
            href="#carousel"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carousel"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      )
    );
  }
}
