import React, { Component } from "react";
import SkeletonFrame from "../../skeleton_frame";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { finAllProjectsRelatedUser } from "../../../actions/project_actions";
import AsideComponent from "./aside_component";
import { Helmet } from "react-helmet";
import axios from "axios";
import SingleProject from "./single_project";
import queryString from "query-string";
import DashboardTemplate from "../../../templates/dashboard_template";
import { SingleSkeletonProject } from "./single_skeleton_project";
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";
import EditProject from "./edit_project";
import EditProjectDashboard from "../createproject/edit_project_dashboard";

class ProjectsforDashboard extends Component {
  constructor() {
    super();
    this.state = {
      pending: false,
      successForm: false,
      previewProject: false,
      skeletonValue: false,
      projectThumbnail: {},
      projectUser: {},
      projectName: "",
      projectDetails: "",
      projectCenter: null,
      file: {},
      imagePreview: "",
      beforeGallery: [],
      AfterGallery: [],
      invoicesGallery: [],
      project: {},
      projectAddress: "",
      showAddress: false,
      fileUploadproject: {},
      fileUploadProgress: 0,
      //
      tempProjectCost: 0,
      thumbnailUrl: "",
      tempBeforeforsave: [],
      tempAfterforsave: [],
      tempInvoiceforsave: [],
      projectType: "",
      thumbnailUploadUrl: "",
      uploadingAllItems: false,
      changingAddress: false,
      projectstartdateDart: "",
      projectstartdate: "",
      projectsfinishdate: "",
      projectStatus: "",
      formValidate: false,
      projects: [],
      toggle: false,
    };
  }

  toggleMenu = () => {
    this.setState({
      toggle: !this.state.toggle,
    });
  };

  componentDidMount() {
    this.props.finAllProjectsRelatedUser();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.state && this.state.projectStatus) {
    }
    if (prevProps.user !== this.props.user) {
      this.setState({
        projectUser: {
          usersub: this.props.user.sub,
          email: this.props.user.email,
          username: this.props.user.username,
        },
      });
    }

    // Check if the relevant data has changed
    if (prevProps.project !== this.props.project) {
      const project = this.props.project;
      // Replace with the actual property

      // Check if the new data is different from the current state
      if (project !== this.state.project) {
        // Update the state only if it's different to avoid infinite loop
        this.setState({ project }, () => {
          this.setState({ projects: project.projects });

          // Optional callback function that runs after the state is updated
          console.log("project address updated:", this.state.project);
        });
      }
    }
  }

  showBeforeGallery() {
    return this.state.beforeGallery.map((item, index) => {
      return (
        <div
          className="shrink-0 w-full bg-green-500 snap-center"
          key={index + 1}
        >
          <div className="mx-auto grid max-w-7xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:px-6 sm:py-0 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
            <div className="items-start">
              <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                <div className="border-t border-gray-200">
                  <dt className="font-medium text-gray-900">
                    photo before preview
                  </dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    <img
                      src={item.preview}
                      className="w-full sm:h-48 sm:w-48 aspect-square"
                    />
                  </dd>
                </div>
              </dl>
            </div>
            <div className="grid grid-cols-1 grid-rows-1 gap-4 sm:gap-6 lg:gap-8">
              <div className="w-full items-start">
                <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                  <div className="border-t border-gray-200">
                    <dt className="font-medium text-gray-900">caption</dt>
                    <dd className="mt-2 text-sm text-gray-500">
                      <textarea
                        className="w-full bg-white h-48 rounded-lg p-4"
                        type="text"
                        name={`beforeItem-${index + 1}`}
                        onChange={this.onChange}
                        value={this.state[`beforeItem-${index + 1}`]}
                      />
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  showAfterGallery() {
    return this.state.AfterGallery.map((item, index) => {
      return (
        <div
          className="shrink-0 w-full bg-green-500 snap-center"
          key={index + 1}
        >
          <div className="mx-auto grid max-w-7xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:px-6 sm:py-0 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
            <div className="items-start">
              <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                <div className="border-t border-gray-200">
                  <dt className="font-medium text-gray-900">
                    photo after preview
                  </dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    <img
                      src={item.preview}
                      className="w-full sm:h-48 sm:w-48 aspect-square"
                    />
                  </dd>
                </div>
              </dl>
            </div>
            <div className="grid grid-cols-1 grid-rows-1 gap-4 sm:gap-6 lg:gap-8">
              <div className="w-full items-start">
                <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                  <div className="border-t border-gray-200">
                    <dt className="font-medium text-gray-900">caption</dt>
                    <dd className="mt-2 text-sm text-gray-500">
                      <textarea
                        className="w-full bg-white h-48 rounded-lg p-4"
                        type="text"
                        name={`afterItem-${index + 1}`}
                        onChange={this.onChange}
                        value={this.state[`afterItem-${index + 1}`]}
                      />
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  showInvoicesGallery() {
    return this.state.invoicesGallery.map((item, index) => {
      return (
        <div
          className="shrink-0 w-full bg-green-500 snap-center"
          key={index + 1}
        >
          <div className="mx-auto grid max-w-7xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:px-6 sm:py-0 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
            <div className="items-start">
              <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                <div className="border-t border-gray-200">
                  <dt className="font-medium text-gray-900">
                    photo invoice item preview
                  </dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    <img
                      src={item.preview}
                      className="w-full sm:h-48 sm:w-48 aspect-square"
                    />
                  </dd>
                </div>
              </dl>
            </div>
            <div className="grid grid-cols-1 grid-rows-1 gap-4 sm:gap-6 lg:gap-8">
              <div className="w-full items-start">
                <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                  <div className="border-t border-gray-200">
                    <dt className="font-medium text-gray-900">caption</dt>
                    <dd className="mt-2 text-sm text-gray-500">
                      <textarea
                        className="w-full bg-white h-48 rounded-lg p-4"
                        type="text"
                        name={`invoiceItem-${index + 1}`}
                        onChange={this.onChange}
                        value={this.state[`invoiceItem-${index + 1}`]}
                      />
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  showProjectsTable(items) {
    return items.map((item, index) =>
      index % 2 == 0 ? (
        <tr
          key={index}
          data-href={`?project=${item.projectID}`}
          className="cursor-pointer"
          onClick={() => (window.location.href = `?project=${item.projectID}`)}
        >
          <td data-label="Thumbnail" className="align-top">
            <small className="text-gray-500" title={item.email}>
              <img
                src={item.thumbnail}
                alt={item.projectID}
                className="aspect-square rounded-lg w-48"
              />
            </small>
          </td>

          <td data-label="Details" className="gap-y-4">
            <h2 className="items-center">
              <span className="text-xl font-bold">{item.projectName}</span>
            </h2>
            <p className="line-clamp-1 p-4 bg-slate-200 rounded-xl items-center">
              {item.details}
            </p>
            {item.address && (
              <span className="flex flex-row gap-x-2 my-2">
                <box-icon name="map"></box-icon>
                <address>{item.address.location}</address>
              </span>
            )}

            <div className="flex flex-row justify-between w-1/4">
              <button
                className="button small red --jb-modal"
                data-target="sample-modal"
                type="button"
              >
                <span className="icon">
                  <a href={`?project=${item.projectID}`}>show</a>
                  {/* <i className="mdi mdi-trash-can"></i> */}
                </span>
              </button>
              <button
                className="button small red --jb-modal"
                data-target="sample-modal"
                type="button"
              >
                <span className="icon">
                  <a href={`?edit=yes&project=${item.projectID}`}>edit</a>
                  {/* <i className="mdi mdi-trash-can"></i> */}
                </span>
              </button>
            </div>
          </td>
        </tr>
      ) : (
        <tr
          key={index}
          data-href={`?project=${item.projectID}`}
          className="cursor-pointer"
          onClick={() => (window.location.href = `?project=${item.projectID}`)}
        >
          <td data-label="Thumbnail" className="align-top">
            <small className="text-gray-500" title={item.email}>
              <img
                src={item.thumbnail}
                alt={item.projectID}
                className="aspect-square rounded-lg w-48"
              />
            </small>
          </td>
          <td data-label="Name" className="gap-y-4">
            <h2 className="items-center">
              <span className="text-xl font-bold">{item.projectName}</span>
            </h2>
            <p className="flex line-clamp-1 p-4 bg-slate-200 rounded-xl min-h-[4rem] items-center">
              {item.details}
            </p>
            {item.address && (
              <span className="flex flex-row gap-x-2 my-2">
                <box-icon name="map"></box-icon>
                <address>{item.address.location}</address>
              </span>
            )}

            <div className="flex flex-row justify-between w-1/4">
              <button
                className="button small red --jb-modal"
                data-target="sample-modal"
                type="button"
              >
                <span className="icon">
                  <a href={`?project=${item.projectID}`}>show</a>
                  {/* <i className="mdi mdi-trash-can"></i> */}
                </span>
              </button>
              <button
                className="button small red --jb-modal"
                data-target="sample-modal"
                type="button"
              >
                <span className="icon">
                  <a href={`?edit=yes&project=${item.projectID}`}>edit</a>
                  {/* <i className="mdi mdi-trash-can"></i> */}
                </span>
              </button>
            </div>
          </td>
        </tr>
      )
    );
  }

  exitFunction = () => {};

  render() {
    var { site } = this.props;
    var { projects, toggle } = this.state;

    if (
      queryString.parse(window.location.search) &&
      queryString.parse(window.location.search).project
    ) {
      var projectID = queryString.parse(window.location.search).project;
      var projectItem = projects.filter(
        (project) => project.projectID === projectID.toString()
      )[0];

      return projectItem && projectItem.projectID ? (
        queryString.parse(window.location.search) &&
        queryString.parse(window.location.search).edit &&
        queryString.parse(window.location.search).edit == "yes" ? (
          <EditProjectDashboard
            projectItem={projectItem}
            site={this.props && this.props.site}
          />
        ) : (
          <SingleProject projectItem={projectItem} />
        )
      ) : (
        <div className="h-[100vh] flex items-center justify-center">
          <Segment>
            <Dimmer active inverted>
              <Loader size="large">Loading</Loader>
            </Dimmer>

            <Image src="https://react.semantic-ui.com/images/wireframe/paragraph.png" />
          </Segment>
        </div>
      );
    } else {
      return (
        <DashboardTemplate
          termName="projects"
          termActive={false}
          termLink={`/dashboard`}
          items={[
            {
              name: "new project",
              itemLink: `/dashboard/settings/createproject`,
            },
            {
              name: "users",
              itemLink: `/dashboard/settings/users`,
            },
            {
              active: true,
              name: "projects",
              itemLink: `/dashboard/settings/projects`,
            },
            // {
            //   name: "themes settings",
            //   itemLink: `/dashboard/settings/themes`,
            // },
          ]}
          contentData={
            <div className="card has-table p-4">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-account-multiple"></i>
                  </span>
                  Projects
                </p>

                <a href="#" className="card-header-icon">
                  <span className="icon">
                    <i className="mdi mdi-reload"></i>
                  </span>
                </a>
              </header>

              <div className="w-full sticky">
                <table className="w-full">
                  <thead className="w-full">
                    <tr className="w-full bg-pink-400">
                      <th className="w-[20%] bg-yellow-300">Thumbnail</th>
                      <th className="w-[80%] bg-yellow-100">Details</th>
                    </tr>
                  </thead>
                  <tbody>{projects && this.showProjectsTable(projects)}</tbody>
                </table>

                <div className="table-pagination hidden">
                  <div className="flex items-center justify-between">
                    <div className="buttons">
                      <button type="button" className="button active">
                        1
                      </button>
                      <button type="button" className="button">
                        2
                      </button>
                      <button type="button" className="button">
                        3
                      </button>
                    </div>
                    <small>Page 1 of 3</small>
                  </div>
                </div>
              </div>
            </div>
          }
          seo={
            <Helmet>
              <meta
                name="description"
                content="admin section of website |  users table section"
              />
              <title>Users page</title>

              {/* <link rel="canonical" href="http://example.com/example" /> */}
            </Helmet>
          }
        />
      );
    }
  }

  renderold() {
    var { site } = this.props;
    var { projects, toggle } = this.state;

    if (queryString.parse(window.location.search).project) {
      var projectID = queryString.parse(window.location.search).project;
      var projectItem = projects.filter(
        (project) => project.projectID === projectID.toString()
      )[0];
    }

    if (projectItem) {
      return <SingleProject projectItem={projectItem} />;
    } else {
      return (
        <>
          <script src="https://unpkg.com/boxicons@2.1.4/dist/boxicons.js"></script>

          <div className="h-[3.5rem] hidden lg:flex">
            <div id="appadmin" className="flex justify-end">
              <aside className="aside is-placed-left is-expanded">
                <div className="aside-tools">
                  <a href="/">website</a>
                  <span>/</span>
                  <div>
                    <b className="font-black">Projects</b>
                  </div>
                </div>
                <div className="menu is-menu-main">
                  <p className="menu-label">General</p>
                  <ul className="menu-list">
                    <li>
                      <a href="/dashboard">
                        <span className="icon">
                          <i className="mdi mdi-desktop-mac"></i>
                        </span>
                        <span className="menu-item-label">Dashboard</span>
                      </a>
                    </li>
                  </ul>
                  <p className="menu-label">Pages</p>
                  <ul className="menu-list">
                    <li className="--set-active-tables-html">
                      <a href="/dashboard/settings/createproject">
                        <span className="icon">
                          <i className="mdi mdi-table"></i>
                        </span>
                        <span className="menu-item-label">
                          Create a project
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="/dashboard/settings/users">
                        <span className="icon">
                          <i className="mdi mdi-square-edit-outline"></i>
                        </span>
                        <span className="menu-item-label">Users</span>
                      </a>
                    </li>
                    <li className="active">
                      <a>
                        <span className="icon">
                          <i className="mdi mdi-account-circle"></i>
                        </span>
                        <span className="menu-item-label">Projects</span>
                      </a>
                    </li>
                    <li>
                      <a href="/dashboard/settings/themes">
                        <span className="icon">
                          <i className="mdi mdi-lock"></i>
                        </span>
                        <span className="menu-item-label">Themes settings</span>
                      </a>
                    </li>
                  </ul>
                  <p className="menu-label">Actions</p>
                  <ul className="menu-list">
                    <li>
                      <a
                        href="/dashboard/settings/header"
                        onclick="alert('Coming soon'); return false"
                        target="_blank"
                        className="has-icon"
                      >
                        <span className="icon">
                          <i className="mdi mdi-credit-card-outline"></i>
                        </span>
                        <span className="menu-item-label">Header section</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/dashboard/settings/slides"
                        onclick="alert('Coming soon'); return false"
                        target="_blank"
                        className="has-icon"
                      >
                        <span className="icon">
                          <i className="mdi mdi-credit-card-outline"></i>
                        </span>
                        <span className="menu-item-label">Slide section</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/dashboard/settings/footer"
                        onclick="alert('Coming soon'); return false"
                        target="_blank"
                        className="has-icon"
                      >
                        <span className="icon">
                          <i className="mdi mdi-credit-card-outline"></i>
                        </span>
                        <span className="menu-item-label">Footer section</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </aside>
              <div id="appadmin">
                <section className="section main-section w-full lg:w-[calc(100vw-15rem)] lg:ml-[15rem]">
                  <div className="sticky top-0 z-50">
                    <div className="w-full h-[3.5rem] mb-4 bgdashboard items-center flex justify-end">
                      <div className="w-[60px] bg-yellow-400 h-full flex items-center justify-around">
                        <button onClick={this.exitFunction}>
                          <span>
                            <i className="fas fa-sign-out-alt"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="card has-table p-4">
                    <header className="card-header">
                      <p className="card-header-title">
                        <span className="icon">
                          <i className="mdi mdi-account-multiple"></i>
                        </span>
                        Projects
                      </p>

                      <a href="#" className="card-header-icon">
                        <span className="icon">
                          <i className="mdi mdi-reload"></i>
                        </span>
                      </a>
                    </header>

                    <div className="w-full sticky">
                      <table className="w-full">
                        <thead className="w-full">
                          <tr className="w-full bg-pink-400">
                            <th className="w-[20%] bg-yellow-300">Thumbnail</th>
                            <th className="w-[80%] bg-yellow-100">Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {projects && this.showProjectsTable(projects)}
                        </tbody>
                      </table>

                      <div className="table-pagination hidden">
                        <div className="flex items-center justify-between">
                          <div className="buttons">
                            <button type="button" className="button active">
                              1
                            </button>
                            <button type="button" className="button">
                              2
                            </button>
                            <button type="button" className="button">
                              3
                            </button>
                          </div>
                          <small>Page 1 of 3</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

          <div className="flex lg:hidden flex-col">
            <>
              <nav className="h-[3.5rem] transition-all delay-200 ease-in-out sticky top-0 z-50">
                <section className="bg-[#111827] text-white hidden md:flex md:items-center h-full mx-auto max-w-7xl px-6 justify-between">
                  <ul className="list-none flex flex-row gap-x-4 items-center">
                    <li>
                      <a className="text-2xl font-bold text-white" href="/">
                        Remodelchiefs
                      </a>
                    </li>
                    <li>
                      <a href="/dashboard/settings/createproject">
                        create project
                      </a>
                    </li>
                    <li>
                      <a href="/dashboard/settings/users">users</a>
                    </li>
                    <li>
                      <a href="/dashboard/settings/projects">projects</a>
                    </li>
                    <li>
                      <a href="/dashboard/settings/themes">themes setting</a>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <button>
                        <box-icon name="log-out"></box-icon>
                      </button>
                    </li>
                  </ul>
                </section>
                <ul className="bg-[#111827] flex items-center md:hidden h-full mx-auto max-w-7xl px-6">
                  <li className="text-white">
                    <button onClick={this.toggleMenu}>
                      {/* <box-icon name="menu-alt-left"></box-icon> */}
                      <i className="bx bx-menu-alt-left text-white" />
                    </button>{" "}
                  </li>
                </ul>
              </nav>
              {toggle && (
                <div className="flex md:hidden">
                  <aside className="aside is-placed-left is-expanded">
                    <div className="aside-tools">
                      <a href="/">website</a>
                      <span>/</span>
                      <div>
                        <b className="font-black">Projects</b>
                      </div>
                    </div>
                    <div className="menu is-menu-main">
                      <p className="menu-label">General</p>
                      <ul className="menu-list">
                        <li>
                          <a href="/dashboard">
                            <span className="icon">
                              <i className="mdi mdi-desktop-mac"></i>
                            </span>
                            <span className="menu-item-label">Dashboard</span>
                          </a>
                        </li>
                      </ul>
                      <p className="menu-label">Pages</p>
                      <ul className="menu-list">
                        <li className="--set-active-tables-html">
                          <a href="/dashboard/settings/createproject">
                            <span className="icon">
                              <i className="mdi mdi-table"></i>
                            </span>
                            <span className="menu-item-label">
                              Create a project
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="/dashboard/settings/users">
                            <span className="icon">
                              <i className="mdi mdi-square-edit-outline"></i>
                            </span>
                            <span className="menu-item-label">Users</span>
                          </a>
                        </li>
                        <li className="active">
                          <a>
                            <span className="icon">
                              <i className="mdi mdi-account-circle"></i>
                            </span>
                            <span className="menu-item-label">projects</span>
                          </a>
                        </li>
                        <li>
                          <a href="/dashboard/settings/themes">
                            <span className="icon">
                              <i className="mdi mdi-lock"></i>
                            </span>
                            <span className="menu-item-label">
                              Themes settings
                            </span>
                          </a>
                        </li>
                      </ul>
                      <p className="menu-label">Actions</p>
                      <ul className="menu-list">
                        <li>
                          <a
                            href="/dashboard/settings/header"
                            onclick="alert('Coming soon'); return false"
                            target="_blank"
                            className="has-icon"
                          >
                            <span className="icon">
                              <i className="mdi mdi-credit-card-outline"></i>
                            </span>
                            <span className="menu-item-label">
                              Header section
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="/dashboard/settings/slides"
                            onclick="alert('Coming soon'); return false"
                            target="_blank"
                            className="has-icon"
                          >
                            <span className="icon">
                              <i className="mdi mdi-credit-card-outline"></i>
                            </span>
                            <span className="menu-item-label">
                              Slide section
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="/dashboard/settings/footer"
                            onclick="alert('Coming soon'); return false"
                            target="_blank"
                            className="has-icon"
                          >
                            <span className="icon">
                              <i className="mdi mdi-credit-card-outline"></i>
                            </span>
                            <span className="menu-item-label">
                              Footer section
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </aside>
                </div>
              )}
              {!toggle && (
                <div>
                  <div className="card has-table p-4">
                    <header className="card-header">
                      <p className="card-header-title">
                        <span className="icon">
                          <i className="mdi mdi-account-multiple"></i>
                        </span>
                        Projects
                      </p>

                      <a href="#" className="card-header-icon">
                        <span className="icon">
                          <i className="mdi mdi-reload"></i>
                        </span>
                      </a>
                    </header>
                  </div>

                  <div className="w-full">
                    <table className="w-full">
                      <thead className="w-full">
                        <tr bg-orange-400 className="w-full bg-pink-400">
                          <th className="w-[20%] bg-yellow-300">Thumbnail</th>
                          <th className="w-[80%] bg-yellow-100">Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {projects && this.showProjectsTable(projects)}
                      </tbody>
                    </table>

                    <div className="table-pagination hidden">
                      <div className="flex items-center justify-between">
                        <div className="buttons">
                          <button type="button" className="button active">
                            1
                          </button>
                          <button type="button" className="button">
                            2
                          </button>
                          <button type="button" className="button">
                            3
                          </button>
                        </div>
                        <small>Page 1 of 3</small>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* from here */}
              {/* <div className="sticky top-0 z-50">
                <div className="w-full h-[3.5rem] mb-4 bgdashboard items-center flex justify-end">
                  nice
                </div>
              </div> */}

              {/* end here */}
            </>
          </div>
        </>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  project: state.project,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  finAllProjectsRelatedUser,
})(ProjectsforDashboard);
