import React, { Component } from "react";
// about
import aboutimg from "../img/sohrab.jpg";
const AboutSection = ({ site }) => {
  return (
    <div className="about wow fadeInUp" data-wow-delay="0.1s">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-6">
            <div className="about-img3">
              {/* <img
                src="https://projects-images-remodel.s3.us-west-1.amazonaws.com/sohrab.jpg"
                alt="Sohrab Ahmadi"
                className="h-[400px] aspect-square object-top object-cover"
              /> */}

              <img
                className="w-[300px] h-auto rounded-full"
                src="https://projects-images-remodel.s3.us-west-1.amazonaws.com/sohrab.jpg"
                // src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-7 col-md-6">
            <div className="section-header text-left">
              <h2>
                Design & Remodel By <br /> Sohrab Ahmadi
              </h2>
            </div>
            <div className="about-text">
              {site && (
                <p>{site.content.replace("the Bay Area", "California")}</p>
              )}

              <a className="btn hidden" href="#">
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
